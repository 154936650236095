import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path:'/login',
        name:'login',
        component:() => import (/* webpackChunkName: "login" */ '../views/login.vue')
    },
    {
        path:'/signIn',
        name:'signIn',
        component:() => import (/* webpackChunkName: "signIn" */ '../views/signIn.vue')
    },
    {
        path:'/repassword',
        name:'repassword',
        component:() => import (/* webpackChunkName: "repassword" */ '../views/repassword.vue')
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/redirect',
        name: 'redirect',
        component: () =>
            import ( /* webpackChunkName: "redirect" */ '../views/redirect.vue')
    },
    {
        path: '/redirect2',
        name: 'redirect2',
        component: () =>
            import ( /* webpackChunkName: "redirect" */ '../views/redirect2.vue')
    },
    //资讯类列表、详情
    {
        path: '/infos',
        name: 'infos',
        component: () =>
            import ( /* webpackChunkName: "infos" */ '../views/infos.vue'),
        redirect: '/detail',
        children: [{
                path: '/detail',
                name: 'detail',
                component: () =>
                    import ( /*webpackChunkName: "detail"*/ '../views/Detail.vue'),
            },
            {
                path: '/List',
                name: 'List',
                component: () =>
                    import ( /*webpackChunkName: "List"*/ '../views/List.vue'),
            },
            {
                path: '/videoList',
                name: 'videoList',
                component: () =>
                    import ( /*webpackChunkName: "videoList"*/ '../views/videoList.vue'),
            },
        ]
    },
    /**
     * 乡宁荟
     */
    {
        path: '/xiangninghui',
        name: 'xiangninghui',
        component: () =>
            import ( /*webpackChunkName: "xiangninghui"*/ '../views/xiangninghui.vue'),
        redirect: '/index',
        children: [{
                path: '/index',
                name: 'xiangninghuiIndex',
                component: () =>
                    import ( /*webpackChunkName: "xiangninghuiIndex"*/ '../views/xiangninghui/index.vue'),
            },
            {
                path: '/xiangninghui/shopping',
                name: 'shopping',
                component: () =>
                    import ( /*webpackChunkName: "shopping"*/ '../views/xiangninghui/shopping.vue'),
            },
            {
                path: '/xiangninghui/sharehouse',
                name: 'sharehouse',
                component: () =>
                    import ( /*webpackChunkName: "sharehouse"*/ '../views/xiangninghui/shareHouse.vue'),
            },

        ]
    },
    //乡宁荟、列表详情
    {
        path: '/xiangninghuidetail',
        name: 'xiangninghuidetail',
        component: () =>
            import ( /*webpackChunkName: "xiangninghuidetail"*/ '../views/xiangninghui/detail.vue'),
        // redirect:'/index',
        children: [{
                path: '/sharehouselist',
                name: 'sharehouselist',
                component: () =>
                    import ( /*webpackChunkName: "sharehouselist"*/ '../views/xiangninghui/list/shareHouseList.vue'),
            },
            {
                path: '/sharehousedetail',
                name: 'sharehousedetail',
                component: () =>
                    import ( /*webpackChunkName: "sharehousedetail"*/ '../views/xiangninghui/detail/shareHouseDetail.vue'),
            },
            {
                path: '/villiagelist',
                name: 'villiagelist',
                component: () =>
                    import ( /*webpackChunkName: "villiage"*/ '../views/xiangninghui/list/villiagelist.vue'),
            },
            {
                path: '/villiagedetail',
                name: 'villiagedetail',
                component: () =>
                    import ( /*webpackChunkName: "villiagedetail"*/ '../views/xiangninghui/detail/villiagedetail.vue'),
            },
            {
                path: '/scenerySpotList',
                name: 'scenerySpotList',
                component: () =>
                    import ( /*webpackChunkName: "scenerySpotList"*/ '../views/xiangninghui/list/scenerySpotList.vue'),
            },
            {
                path: '/scenerySpotdetail',
                name: 'scenerySpotdetail',
                component: () =>
                    import ( /*webpackChunkName: "scenerySpotdetail"*/ '../views/xiangninghui/detail/scenerySpotdetail.vue'),
            },
            {
                path: '/housedetail',
                name: 'housedetail',
                component: () =>
                    import ( /*webpackChunkName: "housedetail"*/ '../views/xiangninghui/detail/housedetail.vue'),
            },
            {
                path: '/houseList',
                name: 'houseList',
                component: () =>
                    import ( /*webpackChunkName: "houseList"*/ '../views/xiangninghui/list/houseList.vue'),
            },
            ///乡宁荟-采摘园
            {
                path: '/shareFarmList',
                name: 'shareFarmList',
                component: () =>
                    import ( /*webpackChunkName: "shareFarmList"*/ '../views/xiangninghui/list/shareFarmList.vue'),
            },
            //特色产品，特色美食
            {
                path: '/productlist',
                name: 'productlist',
                component: () =>
                    import ( /*webpackChunkName: "productlist"*/ '../views/xiangninghui/list/productlist.vue'),
            },
            {
                path: '/productdetail',
                name: 'productdetail',
                component: () =>
                    import ( /*webpackChunkName: "productdetail"*/ '../views/xiangninghui/detail/productdetail.vue'),
            },
            {
                path: '/foodlist',
                name: 'foodlist',
                component: () =>
                    import ( /*webpackChunkName: "foodlist"*/ '../views/xiangninghui/list/foodlist.vue'),
            },
            {
                path: '/fooddetail',
                name: 'fooddetail',
                component: () =>
                    import ( /*webpackChunkName: "fooddetail"*/ '../views/xiangninghui/detail/fooddetail.vue'),
            },
            //特色店铺
            {
                path: '/shopList',
                name: 'shopList',
                component: () =>
                    import ( /*webpackChunkName: "shopList"*/ '../views/xiangninghui/list/shopList.vue'),
            },
            {
                path: '/shopdetail',
                name: 'shopdetail',
                component: () =>
                    import ( /*webpackChunkName: "shopdetail"*/ '../views/xiangninghui/detail/shopdetail.vue'),
            },
            //精品路线
            {
                path: '/bestway',
                name: 'bestway',
                component: () =>
                    import ( /*webpackChunkName: "bestway"*/ '../views/xiangninghui/list/bestway.vue'),
            },
            {
                path: '/bestwaydetail',
                name: 'bestwaydetail',
                component: () =>
                    import ( /*webpackChunkName: "bestwaydetail"*/ '../views/xiangninghui/detail/bestwaydetail.vue'),
            },
        ]
    },


    /*
     *乡宁情
     */
    {
        path: '/xiangningqing',
        name: 'xiangningqing',
        component: () =>
            import ( /*webpackChunkName: "xiangningqing"*/ '../views/xiangningqing/index.vue'),
        redirect: '/talents',
        children: [{
                path: '/talents',
                name: 'xiangningqingTalents',
                component: () =>
                    import ( /*webpackChunkName: "xiangningqingTalents"*/ '../views/xiangningqing/talents.vue'),
            },
            {
                path: '/Personnelfile',
                name: 'xiangningqingPersonnel',
                component: () =>
                    import ( /*webpackChunkName: "xiangningqingPersonnel"*/ '../views/xiangningqing/Personnelfile.vue'),
            },
        ]
    },
    //乡宁情列表、详情
    {
        path: '/xiangningqingdetail',
        name: 'xiangningqingdetail',
        component: () =>
            import ( /*webpackChunkName: "xiangningqingdetail"*/ '../views/xiangningqing/detail.vue'),
        children: [
            //能人列表
            {
                path: '/bestperson',
                name: 'bestperson',
                component: () =>
                    import ( /*webpackChunkName: "bestperson"*/ '../views/xiangningqing/list/bestperson.vue'),
            },
            {
                path: '/bestpersondetail',
                name: 'bestpersondetail',
                component: () =>
                    import ( /*webpackChunkName: "bestpersondetail"*/ '../views/xiangningqing/detail/bestpersondetail.vue'),
            },
            { //事迹列表
                path: '/story',
                name: 'story',
                component: () =>
                    import ( /*webpackChunkName: "story"*/ '../views/xiangningqing/list/story.vue'),
            },
            { //人才列表
                path: '/person',
                name: 'person',
                component: () =>
                    import ( /*webpackChunkName: "story"*/ '../views/xiangningqing/list/person.vue'),
            },
            { //人才详情
                path: '/persondetail',
                name: 'persondetail',
                component: () =>
                    import ( /*webpackChunkName: "story"*/ '../views/xiangningqing/detail/persondetail.vue'),
            },
            { //人才详情
                path: '/persondetail2',
                name: 'persondetail2',
                component: () =>
                    import ( /*webpackChunkName: "persondetail2"*/ '../views/xiangningqing/detail/persondetail2.vue'),
            },
            { //三农经验列表
                path: '/sxjyList',
                name: 'sxjyList',
                component: () =>
                    import ( /*webpackChunkName: "sxjyList"*/ '../views/xiangningqing/list/sxjyList.vue'),
            },
            { //三农经验详情
                path: '/experiencedetail',
                name: 'experiencedetail',
                component: () =>
                    import ( /*webpackChunkName: "experiencedetail"*/ '../views/xiangningqing/detail/experiencedetail.vue'),
            },
        ]
    },


    /*
     *乡宁旺
     */
    {
        path: '/xiangningwang',
        name: 'xiangningwang',
        component: () =>
            import ( /*webpackChunkName: "xiangningwang"*/ '../views/xiangningwang/index.vue'),
        redirect: '/business',
        children: [{
                path: '/enterprise',
                name: 'xiangningwangEnterprise',
                component: () =>
                    import ( /*webpackChunkName: "xiangningwangEnterprise"*/ '../views/xiangningwang/enterprise.vue'),
            },
            {
                path: '/recruit',
                name: 'xiangningwangRecruit',
                component: () =>
                    import ( /*webpackChunkName: "xiangningwangRecruit"*/ '../views/xiangningwang/recruit.vue'),
            },
            {
                path: '/business',
                name: 'xiangningwangBusiness',
                component: () =>
                    import ( /*webpackChunkName: "xiangningwangBusiness"*/ '../views/xiangningwang/business.vue'),
            },
        ]
    },
    //乡宁旺列表 详情
    {
        path: '/xiangningwangdetail',
        name: 'xiangningwangdetail',
        component: () =>
            import ( /*webpackChunkName: "xiangningwangdetail"*/ '../views/xiangningwang/detail.vue'),
        children: [
            //能人列表
            {
                path: '/enterprisedetail',
                name: 'enterprisedetail',
                component: () =>
                    import ( /*webpackChunkName: "enterprisedetail"*/ '../views/xiangningwang/detail/enterprisedetail.vue'),
            },
            { //招商动态列表
                path: '/businesslist',
                name: 'businesslist',
                component: () =>
                    import ( /*webpackChunkName: "businesslist"*/ '../views/xiangningwang/list/businesslist.vue'),
            },
            { //资讯类列表
                path: '/xnwzxList',
                name: 'xnwzxList',
                component: () =>
                    import ( /*webpackChunkName: "xnwzxList"*/ '../views/xiangningwang/list/xnwzxList.vue'),
            },
            { //企业直聘详情
                path: '/recruitdetail',
                name: 'xiangningwangRecruitdetail',
                component: () =>
                    import ( /*webpackChunkName: "xiangningwangRecruitdetail"*/ '../views/xiangningwang/detail/recruitdetail.vue'),
            },
            { //企业直聘详情
                path: '/otherDetail',
                name: 'otherDetail',
                component: () =>
                    import ( /*webpackChunkName: "otherDetail"*/ '../views/xiangningwang/detail/otherDetail.vue'),
            },
        ]
    },
    /* 资源集市 */
    {
        path: '/ziyuanjishi',
        name: 'ziyuanjishi',
        component: () =>
            import ( /*webpackChunkName: "xiangningwang"*/ '../views/ziyuanjishi/index.vue'),
        redirect: '/resource',
        children: [{
                path: '/resource',
                name: 'ziyuanjishiResource',
                component: () =>
                    import ( /*webpackChunkName: "ziyuanjishiResource"*/ '../views/ziyuanjishi/resource.vue'),
            },
            {
                path: '/enterpriseResource',
                name: 'ziyuanjishiEnterpriseResource',
                component: () =>
                    import ( /*webpackChunkName: "ziyuanjishiEnterpriseResource"*/ '../views/ziyuanjishi/enterpriseResource.vue'),
            },
            {
                path: '/equity',
                name: 'ziyuanjishiEuqity',
                component: () =>
                    import ( /*webpackChunkName: "ziyuanjishiEuqity"*/ '../views/ziyuanjishi/equity.vue'),
            },
            {
                path: '/email',
                name: 'email',
                component: () =>
                    import ( /*webpackChunkName: "email"*/ '../views/ziyuanjishi/email.vue'),
            },
            
        ]
    },
    //资源集市列表 详情
    {
        path: '/ziyuanjishidetail',
        name: 'ziyuanjishidetail',
        component: () =>
            import ( /*webpackChunkName: "ziyuanjishidetail"*/ '../views/ziyuanjishi/detail.vue'),
        children: [
            //企业列表
            {
                path: '/enterpriseList',
                name: 'ziyuanjishiEnterpriseList',
                component: () =>
                    import ( /*webpackChunkName: "ziyuanjishiEnterpriseList"*/ '../views/ziyuanjishi/list/enterpriseList.vue'),
            },
            //企业土地列表
            {
                path: '/enterpriseLandList',
                name: 'ziyuanjishienterpriseLandList',
                component: () =>
                    import ( /*webpackChunkName: "ziyuanjishiEnterpriseLandList"*/ '../views/ziyuanjishi/list/enterpriseLandList.vue'),
            },
            {
                path: '/enterpriseLandDetail',
                name: 'ziyuanjishienterpriseLandDetail',
                component: () =>
                    import ( /*webpackChunkName: "ziyuanjishiEnterpriseLandDetail"*/ '../views/ziyuanjishi/detail/enterpriseLandDetail.vue'),
            },
            //资金需求列表
            {
                path: '/enterpriseNeedZJList',
                name: 'ziyuanjishienterpriseNeedZJList',
                component: () =>
                    import ( /*webpackChunkName: "ziyuanjishienterpriseNeedZJList"*/ '../views/ziyuanjishi/list/enterpriseNeedZJList.vue'),
            },
            {
                path: '/enterpriseNeedZJDetail',
                name: 'ziyuanjishienterpriseNeedZJDetail',
                component: () =>
                    import ( /*webpackChunkName: "ziyuanjishienterpriseNeedZJDetail"*/ '../views/ziyuanjishi/detail/enterpriseNeedZJDetail.vue'),
            },
            //项目需求列表
            {
                path: '/enterpriseNeedXMList',
                name: 'ziyuanjishienterpriseNeedXMListt',
                component: () =>
                    import ( /*webpackChunkName: "ziyuanjishienterpriseNeedXMListt"*/ '../views/ziyuanjishi/list/enterpriseNeedXMList.vue'),
            },
            {
                path: '/enterpriseNeedXMDetail',
                name: 'ziyuanjishienterpriseNeedXMDetail',
                component: () =>
                    import ( /*webpackChunkName: "ziyuanjishienterpriseNeedXMDetail"*/ '../views/ziyuanjishi/detail/enterpriseNeedXMDetail.vue'),
            },
            //资产详情
            {
                path: '/propertydetail',
                name: 'propertydetail',
                component: () =>
                    import ( /*webpackChunkName: "propertydetail"*/ '../views/ziyuanjishi/detail/propertydetail.vue'),
            },
        ]
    },
    //三农学院
    {
        path: '/sanxiangxueyuan',
        name: 'sanxiangxueyuan',
        component: () =>
            import ( /*webpackChunkName: "sanxiangxueyuan"*/ '../views/sanxiangxueyuan/index.vue'),
        redirect: '/server',
        children: [{
                path: '/server',
                name: 'sanxiangxueyuanServer',
                component: () =>
                    import ( /*webpackChunkName: "sanxiangxueyuanServer"*/ '../views/sanxiangxueyuan/server.vue'),
            },
            {
                path: '/school',
                name: 'sanxiangxueyuanSchool',
                component: () =>
                    import ( /*webpackChunkName: "sanxiangxueyuanSchool"*/ '../views/sanxiangxueyuan/school.vue'),
            },
            {
                path: '/creater',
                name: 'sanxiangxueyuanCreater',
                component: () =>
                    import ( /*webpackChunkName: "sanxiangxueyuanCreater"*/ '../views/sanxiangxueyuan/creater.vue'),
            },
        ]
    },
    //三农学院列表 详情
    {
        path: '/sanxiangxueyuandetail',
        name: 'sanxiangxueyuandetail',
        component: () =>
            import ( /*webpackChunkName: "sanxiangxueyuandetail"*/ '../views/sanxiangxueyuan/detail.vue'),
        children: [{ //专题讲座列表
                path: '/chair',
                name: 'chair',
                component: () =>
                    import ( /*webpackChunkName: "chair"*/ '../views/sanxiangxueyuan/list/chair.vue'),
            },
            { //专题讲座详情
                path: '/chairDetail',
                name: 'chairDetail',
                component: () =>
                    import ( /*webpackChunkName: "chairDetail"*/ '../views/sanxiangxueyuan/detail/chairDetail.vue'),
            },
        ]
    },


    {
        path: '/sanxiangguangbo',
        name: 'sanxiangguangbo',
        component: () =>
            import ( /*webpackChunkName: "sanxiangxueyuan"*/ '../views/sanxiangguangbo/index.vue'),
        redirect: '/dynamic',
        children: [{
                path: '/dynamic',
                name: 'sanxiangguangboDynamic',
                component: () =>
                    import ( /*webpackChunkName: "sanxiangguangboDynamic"*/ '../views/sanxiangguangbo/dynamic.vue'),
            },
            {
                path: '/policy',
                name: 'sanxiangguangboPolify',
                component: () =>
                    import ( /*webpackChunkName: "sanxiangguangboPolify"*/ '../views/sanxiangguangbo/policy.vue'),
            },
            {
                path: '/datas',
                name: 'sanxiangguangboDatas',
                component: () =>
                    import ( /*webpackChunkName: "sanxiangguangboDatas"*/ '../views/sanxiangguangbo/datas.vue'),
            },
        ]
    },
    {
        path: '/antipoverty',
        name: 'antipoverty',
        component: () =>
            import ( /*webpackChunkName: "antipoverty"*/ '../views/antipoverty/index.vue'),
        redirect: '/responsibility',
        children: [{
                path: '/responsibility',
                name: 'responsibility',
                component: () =>
                    import ( /*webpackChunkName: "responsibility"*/ '../views/antipoverty/responsibility.vue'),
            },
        ]
    },
    {
        path: '/404',
        name: '404',
        component: () => import(/* webpackChunkName: "404" */ '../views/404.vue'),
    },
    {
        path:'*',
        redirect:'/404',
        hidden: true 
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to,from,saveTop){
        if(saveTop){return saveTop;}
        else{return{x:0,y:0}}
    }
})

export default router
