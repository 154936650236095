import axios from '@/utils/axios';
export function SendSms(data){
    /**
     * 获取验证码 
     **/
    return axios({
        method:'post',
        url: '/api/admin/Sms/Send',
        data,
    })
}
export function Verify(data){
    /**
     * 验证码验证
     **/
    return axios({
        method:'put',
        url: '/api/admin/Sms/Verify',
        data,
    })
}
export function Register(data){
    /**
     * 注册 
     **/
    return axios({
        method:'post',
        url: '/api/admin/User/Register',
        data,
    })
}
export function reSetPassword(data){
    /**
     * 重置密码 
     **/
    return axios({
        method:'put',
        url: '/api/admin/User/ReSetPasswordForWeb',
        data,
    })
}
export function login(data){
    /**
     * 登录 
     **/
    return axios({
        method:'post',
        url: '/api/admin/Auth/LoginForWeb',
        data,
    })
}
export function GetUserInfo(){
    /**
     * 查询用户信息 
     **/
    return axios({
        method:'get',
        url: '/api/admin/Auth/GetUserInfo',
    })
}
export function Getbasic(){
    /**
     * 查询用户基本信息 
     **/
    return axios({
        method:'get',
        url: '/api/admin/User/GetBasic',
    })
}