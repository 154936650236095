<template>
    <div id="mapwiew">
    </div>
</template>
<script>
import { loadModules } from 'esri-loader';
//地图服务
// map.addLayer(new esri.layers.ArcGISTiledMapServiceLayer('https://qinghai.tianditu.gov.cn/proxy/resource/Vec_Pub_QH_WMTS_L01_L20/MapServer?appkey=2bf7e27ad0af476b9d7f8b67365a10a5'));
// map.addLayer(new esri.layers.ArcGISTiledMapServiceLayer('https://qinghai.tianditu.gov.cn/proxy/resource/VecLab_Pub_QH_WMTS_L01_L20/MapServer?appkey=2bf7e27ad0af476b9d7f8b67365a10a5'));
export default {
    data(){
        return{
            modules:[
                "esri/Map",
                "esri/layers/TileLayer",'esri/views/MapView',"esri/Graphic",
                "esri/layers/GraphicsLayer",
                "esri/PopupTemplate",
            ],
            view:"",
            gisConstructor:{},
            map:{} 
        }
    },
    props:{
        list:{
            type:Array,
            default:()=>{return []}
        },
        infow:{
            type:Boolean,
            default:false
        },
        center:{
            type:Array,
            default:()=> {return [101.779, 36.617]}
        },
        title:{
            type:String,
            default:'',
        }
    },
    watch:{
        list:{
            handler:function(val){
                this.drawPoint(val)
            },
            deep:true
        }
    },
    mounted(){
        loadModules(this.modules, { css: true }).then(this.loading)
    },
    methods:{
        loading(args){
            console.log(args)
            for (let k in args) {
              let name = this.modules[k].split('/').pop()
              name = name.replace(name[0], name[0].toUpperCase())
              this.gisConstructor[name] = args[k];
            }
            // 地理信息处切片地图
            var mapTileLayer = new this.gisConstructor.TileLayer({
                url:"https://qinghai.tianditu.gov.cn/proxy/resource/VecLab_Pub_QH_WMTS_L01_L20/MapServer?appkey=2bf7e27ad0af476b9d7f8b67365a10a5"
            });
            var mapTileLayer2 = new this.gisConstructor.TileLayer({
                url:"https://qinghai.tianditu.gov.cn/proxy/resource/Vec_Pub_QH_WMTS_L01_L20/MapServer?appkey=2bf7e27ad0af476b9d7f8b67365a10a5"
            });
            this.map = new this.gisConstructor.Map({
                layers:[mapTileLayer2,mapTileLayer],
            });               
            //  创建view视图
            this.view = new this.gisConstructor.MapView({
                container: "mapwiew",
                map: this.map,
                center: this.center,
                zoom: 10
            });
            this.view.on("click",function(e){
                var xy=[Math.round(e.mapPoint.longitude* 1000) / 1000,Math.round(e.mapPoint.latitude* 1000) / 1000]   //转换经纬度
                console.log(xy)
            })    
            this.drawPoint(this.list)
        },
        drawPoint(list){
            this.view.popup.visible = false
            this.map.layers.items = this.map.layers.items.filter(e=>e.type!=="graphics")
            let graphicsLayer = new this.gisConstructor.GraphicsLayer();
            if(this.infow){
                list.forEach(item => {
                    let point = {
                        type: 'point',
                        longitude: item.longitude,
                        latitude: item.latitude,
                    }
                    let simpleMarkerSymbol = {
                        type: "picture-marker",
                        url: require('../assets/img/mapMarket.png'),
                        width: '28px',
                        height: '36px'
                    }
                    let textSymbol = {
                        type: "text",  // autocasts as new TextSymbol()
                        color: "white",
                        haloColor: "#0079ff",
                        haloSize: "1px",
                        text: item.title,
                        xoffset: 3,
                        yoffset: -30,
                        font: {  // autocasts as new Font()
                            size: 12,
                            weight: "bold"
                        }
                    };                    
                    let popupTemplate = new this.gisConstructor.PopupTemplate({
                        "title": item.title,
                        "content": `
                            <div class="win-box">
                                <div class="photo">
                                    <img src="${window.location.origin + (item.photoUrls && item.photoUrls.split(',')[0])}" >
                                </div>
                                <div class="info">
                                    <div>${item.buildArea}m² ${item.houseType} ${item.houseLayout} ${item.decoration}</div>
                                    <div>${item.features}</div>
                                    <div>联系电话：${item.telephone}</div>
                                </div>
                            </div>
                        `
                    })
                    let pointGraphic = new this.gisConstructor.Graphic({
                        geometry: point,
                        symbol: simpleMarkerSymbol,
                        popupTemplate:popupTemplate
                    });
                    let pointGraphic2 = new this.gisConstructor.Graphic({
                        geometry: point,
                        symbol:textSymbol,
                    })
                    graphicsLayer.add(pointGraphic)
                    graphicsLayer.add(pointGraphic2);
                });
            }else{
                let point = {
                    type: 'point',
                    longitude: this.center[0],
                    latitude: this.center[1],
                }
                let simpleMarkerSymbol = {
                    type: "picture-marker",
                    url: require('../assets/img/mapMarket.png'),
                    width: '28px',
                    height: '36px'
                }
                let textSymbol = {
                    type: "text",  // autocasts as new TextSymbol()
                    color: "white",
                    haloColor: "#0079ff",
                    haloSize: "1px",
                    text: this.title,
                    xoffset: 3,
                    yoffset: -30,
                    font: {  // autocasts as new Font()
                        size: 12,
                        weight: "bold"
                    }
                };
                let pointGraphic = new this.gisConstructor.Graphic({
                    geometry: point,
                    symbol: simpleMarkerSymbol,
                });
                let pointGraphic2 = new this.gisConstructor.Graphic({
                    geometry: point,
                    symbol:textSymbol,
                })
                graphicsLayer.add(pointGraphic)
                graphicsLayer.add(pointGraphic2);
            }
            this.map.add(graphicsLayer);
        }, 
    }
}
</script>

<style  scoped>
#mapwiew{
    width: 100%;
    height: 100%;
    background: #fff;
}
#mapwiew >>> .esri-ui-manual-container>.esri-component .esri-attribution__powered-by{
    display: none;
}
#mapwiew >>> .wininfo{
    display: flex ;
    padding: 10px ;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
}
#mapwiew >>> .info{
    font-size: 14px;
    line-height: 22px;
    color: #777;
}
#mapwiew >>> .photo{
    /* width: 180px; */
    height: 130px;
}
#mapwiew >>> .photo img{
    object-fit: cover;
}
</style>