<template>
    <div class="bestman">
        <div class="top">
            <div class="left">
                <!-- <div class="imgbox" @click="goDetail(p1.id)">
                    <el-image fit="cover" style="width:100%;height:100%"  :src="p1.photoUrls"></el-image>
                </div>
                <div class="title ellipsis">{{p1.title}}</div> -->
                <div class="r-top clearfix">
                    
                    <div class="info fl">
                        <div class="name">{{p1.title}}</div>
                        <div class="infos">
                            {{regHtml(p1.content)}}
                        </div>
                    </div>
                    <div class="img-box fl" @click="goDetail(p1.id)">
                        <el-image fit="cover" style="width:100%;height:100%"  :src="p1.photoUrls[0]"></el-image>
                    </div>
                </div>
                <!-- <div class="t-bom">
                    <div>
                        <div class="imgbox" @click="goDetail(p3.id)">
                            <el-image fit="cover" style="width:100%;height:100%"  :src="p3.photoUrls"></el-image>
                        </div>
                        <div class="title ellipsis">{{p3.title}}</div>
                    </div>
                    <div>
                        <div class="imgbox" @click="goDetail(p4.id)">
                            <el-image fit="cover" style="width:100%;height:100%"  :src="p4.photoUrls"></el-image>
                        </div>
                        <div class="title ellipsis">{{p4.title}}</div>
                    </div>
                </div> -->
            </div>
            <div class="right">
                <div class="r-top clearfix">
                    <div class="img-box fl" @click="goDetail(p2.id)">
                        <el-image fit="cover" style="width:100%;height:100%"  :src="p2.photoUrls[0]"></el-image>
                    </div>
                    <div class="info fl">
                        <div class="name">{{p2.title}}</div>
                        <div class="infos">
                            {{regHtml(p2.content)}}
                        </div>
                    </div>
                </div>
                <!-- <div class="t-bom">
                    <div>
                        <div class="imgbox" @click="goDetail(p5.id)">
                            <el-image fit="cover" style="width:100%;height:100%"  :src="p5.photoUrls"></el-image>
                        </div>
                        <div class="title ellipsis">{{p5.title}}</div>
                    </div>
                    <div>
                        <div class="imgbox" @click="goDetail(p6.id)">
                            <el-image fit="cover" style="width:100%;height:100%"  :src="p6.photoUrls"></el-image>
                        </div>
                        <div class="title ellipsis">{{p6.title}}</div>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="feature-carousel">
            <div class="carousel-box">
                <ul class="carousel-inner clearfix" :style="`width:${carData.length*323.75}px;transform:translateX(${translateNum}px)`">
                    <li class="carousel-item fl" v-for="(item,index) in carData" :key="index+'ii'">
                        <div class="carousel-item-pic" @click="goDetail(item.id)">
                            <el-image style="width:100%;height:100%" fit="cover" :src="item.photoUrls[0]"></el-image>
                        </div>
                        <div class="carousel-item-info">
                            <p class="ellipsis">{{item.title}}</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="btn-prev" @click="prev" :class="{disabled:translateNum==0}"><i class="el-icon-arrow-left"></i></div>
            <div class="btn-next" @click="next" :class="{disabled:translateNum==carData.length*(-323.75)+1295}"><i class="el-icon-arrow-right"></i></div>
        </div>
    </div>
</template>
<script>
import { GetInformationPage } from '@/api/home'
import { regHtml } from '../../utils/reg';
export default {
    data(){
        return{
            p1:{photoUrls:require('../../../public/img/home/p1.png'),title:'刘少军   授人以鱼更授人以渔'},
            p2:{photoUrls:require('../../../public/img/home/p2.png'),title:'刘冬梅',content:"青海西宁创业团队带头人， 对乡村开发，经济发展做出重大贡献."},
            p3:{photoUrls:require('../../../public/img/home/p3.png'),title:'牟雨晴',content:"青海西宁创业团队带头人， 对乡村开发，经济发展做出重大贡献."},
            p4:{photoUrls:require('../../../public/img/home/p4.png'),title:'柴达木',content:"青海西宁创业团队带头人， 对乡村开发，经济发展做出重大贡献."},
            p5:{photoUrls:require('../../../public/img/home/p4.png'),title:'柴达木',content:"青海西宁创业团队带头人， 对乡村开发，经济发展做出重大贡献."},
            p6:{photoUrls:require('../../../public/img/home/p4.png'),title:'柴达木',content:"青海西宁创业团队带头人， 对乡村开发，经济发展做出重大贡献."},
            params:{
                currentPage: 1,
                filter: {title: "", infoType: 249252335243333},
                pageSize: 8,
            },
            carData:[{photoUrls:[]},{photoUrls:[]},{photoUrls:[]},{photoUrls:[]},{photoUrls:[]}],
            translateNum:0
        }
    },
    computed:{
        regHtml:()=>regHtml
    },
    created(){
    },
    mounted(){
        this.getPageData();
    },
    methods:{
        getPageData(){
            GetInformationPage(this.params).then(res=>{
                if (res.data?.success == true) {
                    res.data.data.list.forEach(e=>{e.photoUrls = e.photoUrls.split(',')})
                    this.pageList = res.data.data.list;
                    this.pageList.forEach((item,k)=>{
                        this[`p${k+1}`] = item
                    })
                    this.carData = res.data.data.list.slice(2,8)
                }
            })

        },
        goDetail(id){
            
            id&&this.$router.push({path:'/detail',query:{id,type:'能人介绍',oParams:JSON.stringify(this.params)}})
        },
        prev(){
            if(this.translateNum==0) return false;
            this.translateNum += 323.75;
        },
        next(){
            if(this.translateNum==this.carData.length*(-323.75)+1295) return false;
            this.translateNum -= 323.75;
        },
    }
}
</script>
<style scoped>
    .btn-prev{
    left: 0;
}
.btn-next{
    right: 0;
}
.btn-prev.disabled,.btn-prev.disabled:hover,.btn-next.disabled,.btn-next.disabled:hover{
    color: #ccc;
}
.btn-prev:hover,.btn-next:hover{
    color: #1c91ff;
    background: #e3effa;
}
.btn-prev,.btn-next{
    width: 40px;
    height: 100px;
    line-height: 100px;
    border-radius: 2px;
    text-align: center;
    font-size: 40px;
    color: #0169ca;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: rgba(241, 245, 249,.3);
    cursor: pointer;
}

.carousel-inner{
    width: 1800px;
    transition: transform 1.5s;
}
.carousel-item-info{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    line-height: 50px;
    background: rgba(0, 0, 0, .5);
    border-radius: 10px;
    font-size: 20px;
    color: #fff;
    padding: 0 20px 0 20px;
    z-index: 2;
}
.carousel-item-pic{
    width: 308.75px;
    height: 310px;
    cursor: pointer;
}
.carousel-item{
    width: 308.75px;
    height: 310px;
    margin-right: 15px;
    /* background: #eee; */
    /* padding: 30px 5px; */
    box-sizing: border-box;
    position: relative;
}
.carousel-item:hover{
    box-shadow: 5px 10px 8px #889;
}
.carousel-item:hover .carousel-item-info{
    background: #367bff;
    color: #fff;
}
.carousel-item:hover .carousel-item-info span{
    color: #fff;
}
.carousel-box{
    height: 310px;
    width: 1280px;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
}
.feature-carousel{
    height: 310px;
    width: 100%;
    position: relative;
    margin-top: 30px ;
}
.top{
    display: flex;
    justify-content: space-between;
}
.left,.right{
    width: calc(50% - 5px);
    height: 330px;
    overflow: hidden;
    position: relative;
}
.left .name{
    text-align: right;
    padding-right: 20px;
}
.imgbox{
    width: 100%;
    height: 100%;
    cursor: pointer;
}
.img-box{
    width: 260px;
    height: 240px;
    cursor: pointer;
}
.title{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    line-height: 100px;
    background: rgba(0, 0, 0, .5);
    border-radius: 10px;
    padding: 0 20px 0 20px;
    font-size: 20px;
    color: #fff;
    z-index: 2;
}
.r-top{
    height: 310px;
    background: #f3fbff;
    padding:35px 20px;
}
.info{
    width: calc(100% - 300px);
    padding: 20px;
    font-size: 18px;
}
.infos{
    font-size: 16px;
    color: #898989;
    line-height: 30px;
    margin-top: 10px;
    text-indent: 20px;
    display:-webkit-box;
    overflow:hidden;
    text-overflow:ellipsis;
    -webkit-line-clamp:4;
    -webkit-box-orient:vertical;
}
.t-bom{
    display: flex;
    justify-content: space-between;
    height: 310px;
    margin-top: 20px;
}
.t-bom>div{
    width: 48%;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
}
.t-bom>div .title{
    line-height: 50px;
}
</style>