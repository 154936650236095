<template>
    <div class="home" >
        <oNav/>
        <!-- banner区域-->
        <div class="banner">
            <div>
                <el-carousel trigger="click" height="600px" :interval="6000" arrow="never">
                    <el-carousel-item  >
                        <div class="banner1"></div>
                    </el-carousel-item>
                    <el-carousel-item  >
                        <div class="banner2"></div>
                    </el-carousel-item>
                    <el-carousel-item >
                        <div class="banner3"></div>
                    </el-carousel-item>
                </el-carousel>
            </div>
            
            <!-- <oSidebar /> -->
        </div>
        <div class="searchmain">
            <!-- 搜索 -->
            <div class="searchBox">
                <div class="container" :class="isShowSearch?'':'search-all-bottom'">
                    <el-input v-model="inputValue" :class="isShowSearch?'input2':''"  placeholder="输入关键词查询"
                    @input="getSearch" @focus="showSearch" @blur="hideSearch"></el-input>
                    <el-button type="primary" @click="getAccurateSearch">搜索</el-button>
                    <div class="search-box " v-show="isShowSearch">
                        <div v-for="(sear,index) in showData" class="search-list" :key="index" @mouseenter="getSearchList(sear.url)" @mouseleave="clearSearchList">
                            <a :href="sear.url" @click="aHideSearch2">
                                <div :class="sear.url == mouseKey ? 'search-title' :''">{{sear.title}}</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="hot-key">
                <span>热词：</span>
                <span v-for="item in hotKeys" :key="item+'key'" @click="inputValue = item">{{item}}</span>
            </div>
        </div>
        <div class="theme w-1280">
            <div class="theme-item" @click="$router.push('/index')" style="cursor:pointer">
                <div class="theme-title">
                    <img src="../assets/img/theme-title1.png" alt="">
                </div>
                <div class="theme-main">
                    <div>
                        <img src="../assets/img/theme1.jpg" width="100%" alt="">
                    </div>
                    <div class="te-name">市民下乡带活农民</div>
                </div>
            </div>
            <div class="theme-item">
                <div class="theme-title" @click="$router.push('/talents')">
                    <img src="../assets/img/theme-title2.png" alt="">
                </div>
                <div class="theme-main">
                    <div style="padding:0 20px;">
                        <video class="videotag" :src="video1" poster="../../public/img/home/video1.png" controls width="100%" height="372px"></video>
                    </div>
                    <div class="te-name">能人回乡激活农业</div>
                </div>
            </div>
            <div class="theme-item" @click="$router.push('business')" style="cursor:pointer">
                <div class="theme-title">
                    <img src="../assets/img/theme-title3.png" alt="">
                </div>
                <div class="theme-main">
                    <div>
                        <img src="../assets/img/theme3.jpg" width="100%" alt="">
                    </div>
                    <div class="te-name">企业兴乡盘活农村</div>
                </div>
            </div>
        </div>
        <!-- 宣传-->
        <div class="serve  w-1280">
            <ul class="serve-meun">
                <li class="serve-meun-item" v-for="(item,index) in serveMenu" :key="item.name + 'menu'" @click="goHOT(item.url)">
                    <div class="bg-box" :style="`background: url(${require('../assets/img/hot-icon/'+(index+1)+'.png')}) no-repeat center center`"></div>
                    <div class="router-box">
                        {{item.name}}
                    </div>
                </li>
            </ul>
            <div class="method">
                <div class="method-l">
                    <div >
                        <video class="videotag" :src="video2" poster="../../public/img/home/zz.png" controls width="100%" height="426px"></video>
                    </div>
                </div>
                <div class="method-r">
                    <div class="m-header">
                        <div class="mh-top"></div>
                        <div class="mh-bottom"></div>
                        <h3 class="method-h3"><span>西宁市支持"三乡工程"30条措施</span> </h3>
                    </div>
                    <div class="method-list">
                        <div class="m_info">
                            <div class="method-item clearfix" v-for="(item,index) in methods30" :key="index+'method'">
                                <div class="mi-l fl"></div>
                                <div class="mi-r fr" v-html="item"></div>
                            </div>
                            
                        </div>
                        
                        <div class="triangle"></div>
                    </div>
                </div>
            </div>
        </div>
        <!--news 美丽乡村-->
        <div class="news mt-20">
            <table></table>
            <div class="floor-title w-1400  mt-20">
                <img src="../assets/img/hot-icon/title1.png" alt="">
            </div>
            <div class="mt-20 w-1280 pb-20">
                <news ></news>
                <div class="more" @click="$router.push('/villiagelist')">
                    <span>查看更多</span>
                    <img src="../assets/img/hot-icon/more.png" alt="">
                </div>
            </div>

        </div>
        <!--精品路线地图-->
        <div class=" mt-20">
            <table></table>
            <div class="floor-title w-1400  mt-20">
                <img src="../assets/img/hot-icon/title1_1.png" alt="">
            </div>
            <div class="mt-20 w-1280 pb-20">
                <!-- <div style="height:580px;background:url(/img/home/map.png) no-repeat;cursor: pointer;" ></div> -->
                <betterway></betterway>
            </div>

        </div>
        <!--特色景点-->
        <div class="scenery">
            <table></table>
            <div class="floor-title w-1400 ">
                <img src="../assets/img/hot-icon/title5.png" alt="">
            </div>
            <div class="mt-20 w-1280 pb-20">
                <scenery :video4="video4" />
            </div>
        </div>
        <!--news 能人风采-->
        <div class="bestMan mt-20">
            <table></table>
            <div class="floor-title w-1400  mt-20">
                <img src="../assets/img/hot-icon/title2.png" alt="">
            </div>
            <div class="mt-20 w-1280 pb-20">
                <bestMan ></bestMan>
                <div class="more" @click="$router.push('/talents')">
                    <span>查看更多</span>
                    <img src="../assets/img/hot-icon/more.png" alt="">
                </div>
            </div>

        </div>
        <!--chuangke 创客空间-->
        <div class="chuangke mt-20">
            <table></table>
            <div class="floor-title w-1400  mt-20">
                <img src="../assets/img/hot-icon/title3.png" alt="">
            </div>
            <div class="mt-50 w-1280 pb-20">
                <chuangke ></chuangke>
            </div>

        </div>
        <!--enterPrise 企业展示-->
        <div class="enterPrise">
            <table></table>
            <div class="floor-title w-1400 ">
                <img src="../assets/img/hot-icon/title4.png" alt="">
            </div>
            <div class=" w-1280 pb-20">
                <enterPrise ></enterPrise>
            </div>

        </div>
        <!--特色住宿-->
        <div class="hotel  bg-e">
            <table></table>
            <div class="floor-title w-1400 ">
                <img src="../assets/img/hot-icon/title6.png" alt="">
            </div>
            <div class="mt-20 w-1280 pb-20">
                <hotel />
                <div class="more" @click="$router.push('/xiangninghui/sharehouse')">
                    <span>查看更多</span>
                    <img src="../assets/img/hot-icon/more.png" alt="">
                </div>
            </div>
        </div>
        <!--资源集市-->
        <div class="house mt-40 bg-e">
            <table></table>
            <div class="floor-title w-1400 ">
                <img src="../assets/img/hot-icon/title7.png" alt="">
            </div>
            <div class="mt-20 w-1280 pb-20">
                <house />
            </div>
        </div>
        

        <!--招商信息-->
        <div class="business ">
            <table></table>
            <div class="floor-title w-1400 ">
                <img src="../assets/img/hot-icon/title8.png" alt="">
            </div>
            <div class="mt-20 w-1280 pb-20">
                <business />
                <div class="more" @click="$router.push('/business')">
                    <span>查看更多</span>
                    <img src="../assets/img/hot-icon/more.png" alt="">
                </div>
            </div>
        </div>
        <!-- footer-->
        <!-- <div style="height:100px"> </div> -->
    </div>
</template>

<script>
// @ is an alias to /src
import{GetXnInformation,viewInfoAdd} from '@/api/home'
import oNav from '@/components/nav'
// import oSidebar from '@/components/sidebar'
import news from '@/components/home/news.vue'
import bestMan from '@/components/home/bestMan.vue'
import chuangke from '@/components/home/chuangke.vue'
import enterPrise from '@/components/home/enterPrise.vue'
import house from '@/components/home/house.vue'
import business from '@/components/home/business.vue'
import scenery from '@/components/home/scenery.vue'
import hotel from '@/components/home/hotel.vue'
import betterway from '@/components/home/betterway.vue'
import { GetDetail } from '@/api/xiangningqing'
import { GetVideoInfoPage } from "@/api/xiangningqing";


export default {
    name: 'Home',
    components: {
        news,
        bestMan,
        chuangke,
        enterPrise,
        house,
        business,
        scenery,
        hotel,
        oNav,
        betterway
        // oSidebar,
    },
    data(){
        return{
            inputValue:'',
            showData:[],
            actMethods:[],
            mouseKey:"",
            isShowSearch:false,
            isControlShow:true,
            methods30:[],
            video1:"",
            video2:"",
            video3:"",
            video4:"",
            hotKeys:[
                '共享农庄','能人风采','盘活资源','三农课堂','政策红包','乡村旅游',//'品牌建设',
            ],
            serveMenu:[//'乡村旅游','闲置农资','三农课堂','人才档案','招商引资','政策补贴'
                {name:'乡村旅游',url:'/index'},
                {name:'闲置农资',url:'/resource'},
                {name:'三农课堂',url:'/school'},
                {name:'人才档案',url:'/Personnelfile'},
                {name:'招商引资',url:'/business'},
                {name:'政策补贴',url:'/policy'},
                
            ],
            sourceData: [
                {
                title: "乡宁荟",
                url: "/xiangninghui",
                },
                {
                title: "乡宁游",
                url: "/xiangninghui",
                },
                {
                title: "共享农庄",
                url: "/xiangninghui/sharehouse",
                },
                {
                title: "乡宁购",
                url: "/xiangninghui/shopping",
                },
                {
                title: "乡宁情",
                url: "/xiangningqing",
                },
                {
                title: "能人风采",
                url: "/talents",
                },
                {
                title: "人才档案",
                url: "/Personnelfile",
                },
                {
                title: "乡宁旺",
                url: "/business",
                },
                {
                title: "招商引资",
                url: "/business",
                },{
                title: "企业展示",
                url: "/enterprise",
                },{
                title: "企业直聘",
                url: "/recruit",
                },
                {
                title: "资源集市",
                url: "/resource",
                },
                {
                title: "盘活资源",
                url: "/resource",
                },
                {
                title: "企业资源",
                url: "/enterpriseResource",
                },
                {
                title: "产权交易",
                url: "/equity",
                },
                {
                title: "三农学院",
                url: "/server",
                },
                {
                title: "三农服务",
                url: "/server",
                },
                {
                title: "三农课堂",
                url: "/school",
                },
                {
                title: "创客空间",
                url: "/creater",
                },
                {
                title: "三农广播",
                url: "/dynamic",
                },
                {
                title: "三农动态",
                url: "/dynamic",
                },
                {
                title: "政策红包",
                url: "/policy",
                },
                {
                title: "数据中心",
                url: "/datas",
                },
                // {title:'品牌建设',url:'/list?type=相关资讯&title=品牌建设'},
                // {title:'输出低建设',url:'/list?type=相关资讯&title=输出低建设'},
                // {title:'绿色有机农蓄产品输出地建设',url:'/list?type=相关资讯&title=绿色有机农蓄产品输出地建设'},
            ],   
        }
    },
    methods:{
        //浏览记录
        viewInfoRecord(){
            viewInfoAdd({
                objectId: 100000000000000,
                tableName: "t_s_home",
                viewNumber: 1
            }).then(res=>{
                // console.log(res.data)
            })
        },
        //获取视频
        GetVideoList() {
            const params1 ={ //能人事迹
                currentPage: 1,
                pageSize: 1,
                filter: {
                    type: "能人事迹",
                    videoName:"湟源：下脖项村李耀萍"
                },
            }
            GetVideoInfoPage(params1).then((res) => {
                this.video1 = res.data.data.list[0]?.videoUrl||'';
            });
            const params2 ={ //三农主题
                currentPage: 1,
                pageSize: 1,
                filter: {
                    type: "三乡主题",
                    videoName:"乡村振新"
                },
            }
            GetVideoInfoPage(params2).then((res) => {
                this.video2 = res.data.data.list[0]?.videoUrl||'';
            });
            const params3 ={ //美丽乡村
                currentPage: 1,
                pageSize: 1,
                filter: {
                    type: "美丽乡村",
                    videoName:"乡村振新"
                },
            }
            // GetVideoInfoPage(params3).then((res) => {
            //     this.video3 = res.data.data.list[0].videoUrl;
            // });
            const params4 ={ //特色景点
                currentPage: 1,
                pageSize: 1,
                filter: {
                    // type: "特色景点",
                    videoName:"卡阳云顶星空营地"
                },
            }
            GetVideoInfoPage(params4).then((res) => {
                this.video4 = res.data.data.list[0]?.videoUrl||'';
            });
        },
        get30method(){
            GetDetail('267644620173381').then((res) => {
                let str = res.data.data.content
                let reg = new RegExp('<p>'+'(.*?)'+'</p>','g')
                let arr = str.match(reg);
                this.methods30 = arr.map(item=>item.replace(/<[^>]+>/g,''))
                // arr.forEach(item=>{
                //     item = item.replace(/<[^>]+>/g,'')
                //     // console.log(a)
                // })
                let oDom = document.getElementsByClassName('m_info')[0];
                let oTop = 0;
                this.$nextTick(()=>{

                    let timer = setInterval(function(){
                        // console.log(oDom.scrollTop);roll
                        oDom.scrollTop+=1;
                        if(oDom.scrollTop+oDom.clientHeight == oDom.scrollHeight){
                            console.log('lalala')
                            clearInterval(timer)
                        }
                    },30)
                })
            })
        },
        goHOT(url){
            this.$router.push(url);
        },
        //获取资讯
        getXnInformation(){
            const data={currentPage:1,pageSize:10};
            GetXnInformation(data).then(res=>{
                console.log(res.data);
            })
        },
        getAccurateSearch() {
            
            let keys = {
                '共享农庄':'/xiangninghui/sharehouse',
                '能人风采':'/talents',
                '盘活资源':'/resource',
                '三农课堂':'/school',
                '政策红包':'/policy',
                '乡村旅游':'/index',
                // '品牌建设':'/list?type=相关资讯&title=品牌建设',
                // '输出低建设':'/list?type=相关资讯&title=输出低建设',
                // '绿色有机农蓄产品输出地建设':'/list?type=相关资讯&title=绿色有机农蓄产品输出地建设',
            }
            if(this.inputValue){
                // if(this.sourceData.find(e=>e.title === this.inputValue))
                let obj = this.sourceData.find(e=>e.title === this.inputValue)
                if(obj){
                    this.$router.push(obj.url);
                }else{
                    this.inputValue = ''
                }
            }
        },
        getSearch(){
            let keyWord=this.inputValue.toLowerCase();
            let arr=[];
            arr=this.sourceData.filter((res)=>{
                if(res.title.toLowerCase().indexOf(keyWord)!==-1){
                    return res;
                }
            });
            this.showData=arr;
            this.inputValue==""?(this.showData=this.sourceData):'';
            this.showData.length==0?(this.isShowSearch=false):(this.isShowSearch=true);
        },
        showSearch() {
            if(this.inputValue==""){
                this.isShowSearch=true;
                this.showData=this.sourceData;
            }else{
                if(this.showData.length==0){
                    this.isShowSearch=false;
                }else{
                    this.isShowSearch=true;
                }
            }
        },
        //判断区域焦点是否隐藏
        hideSearch(){
            this.showData.length==0?(this.isShowSearch=false):"未查到数据";
            this.isControlShow ? "" : (this.isShowSearch = false);
            
        },
        aHideSearch2(){
            this.isShowSearch=false;
        },
        //鼠标进入区域改变文字颜色
        getSearchList(e){
            this.mouseKey=e;
            this.isControlShow=true;
        },
        //鼠标离开区域清空文字颜色
        clearSearchList(){
            this.mouseKey="";
            this.isControlShow=false;
        },
        //可以做发送后台进行深层次搜索
        // getAccurateSearch(){}
       
    },
    created(){
        this.showData=JSON.parse(JSON.stringify(this.sourceData)),
        this.GetVideoList()
    },
    mounted(){
        this.getXnInformation();
        this.get30method();
        this.viewInfoRecord();
    }
}

</script>
<style scoped>
.enterPrise{
    height: 510px;
    background: url(../assets/img/bg/enterPrise-bg.png) no-repeat center center;  
}
.enterPrise .floor-title{
    padding: 10px 0;
}
.mt-50{
    margin-top: 60px;
}
.chuangke{
    height: 860px;
    background: url(../assets/img/bg/chuangke-ng.png) no-repeat center center;
}
.more{
    width: 240px;
    line-height: 42px;
    font-size: 16px;
    color: #898989;
    border: 2px solid #ccc;
    margin: 80px auto 40px auto;
    text-align: center;
    cursor: pointer;
    transition: all .8s;
}
.more:hover{
    background: rgb(211, 223, 240);
    /* border-color: #eee; */
    /* color: #fff; */
}
.more>span{
    margin-right: 20px;
}
.news,.hotel,.business,.scenery{
    background: #f1f5f9;
}
.method-h3::before{
    content: '';
    display: inline-block;
    width: 30px;
    height: 60px;
    background: url(../assets/img/hot-icon/methed-hbg.png) no-repeat center center;
}
.method-h3 span{
    display: inline-block;
    vertical-align: top;
    margin-left: 10px;
}
.mi-l{
    width: 42px;
    height: 42px;
}
.method-item{
    margin-bottom: 6px;
}
.m_info{
    height: 320px;
    overflow-y:auto ;
}
.m_info::-webkit-scrollbar {
    width:5px;
}
.m_info::-webkit-scrollbar-track {
    -webkit-box-shadow:inset 0 0 6px rgba(0,0,0,0.1);
    border-radius:10px;
    /* display: none; */
}
.m_info::-webkit-scrollbar-thumb {
    border-radius:1px;
    background:#20d0d3;
    /* -webkit-box-shadow:inset006pxrgba(0,0,0,0.5); */
}
.method-item:nth-child(3n+1) .mi-l{
    background: url(../assets/img/hot-icon/arrow1.png) no-repeat center 8px;    
}
.method-item:nth-child(3n+2) .mi-l{
    background: url(../assets/img/hot-icon/arrow2.png) no-repeat center 8px;    
}
.method-item:nth-child(3n+3) .mi-l{
    background: url(../assets/img/hot-icon/arrow3.png) no-repeat center 8px;    
}
.mi-r{
    width: calc(100% - 42px);
    font-size: 18px;
    line-height: 30px;
}
.method-list{
    padding: 20px 10px;
    height:366px;
    background: #e7f1fc;
    margin-left: 10px;
    position: relative;
    overflow: hidden;
}
.triangle{
    position: absolute;
    left: -42px;
    bottom: 0;
    /* background: #fff; */
    border-left: 42px solid transparent;
    border-right: 42px solid transparent;
    border-bottom: 42px solid #fff;
}
.m-header{
    position: relative;
    
}
.mh-top{
    height: 35px;
    background-image: linear-gradient(to right,#1379e1,#fff);
    border-radius: 0 0 0 10px;
}
.mh-bottom{
    height: 25px;
    margin-left: 10px;
    background-image: linear-gradient(to right,#1379e1,#fff);
}
.method-h3{
    line-height: 60px;
    width: 100%;
    position:absolute;
    top: 0;
    left: 0;
    font-size: 22px;
    color: #fff;
    padding-left: 50px;
}
.method{
    display: flex;
}
.method-l{
    width: 50%;
    padding-right: 10px;
    min-height: 426px;
    /* border-bottom: 1px solid #e7f1fc; */
}
.method-r{
    padding-left: 10px;
    width: 50%;
}
.me-name{
    line-height: 40px;
    font-size: 20px;
    padding-left: 16px;
}
.theme{
    display: flex;    
}
.theme-title{
    text-align: center;
    cursor: pointer;
}
.theme-item{
    width: 25%;
    padding-top: 30px;
}
.te-name{
    font-size: 28px;
    text-align: center;
    width: 90%;
    margin: 0 auto;
    line-height: 50px;
    border-radius: 5px;
}
.theme-main{
}
.theme-item:nth-child(1) .theme-title{
    text-align: right;
}
.theme-item:nth-child(1) .theme-main,.theme-item:nth-child(3) .theme-main{
    padding-top: 30px;
}
.theme-item:nth-child(2) .theme-main{
    padding-top: 10px;
}
.theme-item:nth-child(1) .theme-main .te-name,.theme-item:nth-child(3) .theme-main .te-name{
    background: linear-gradient(#52daff,#aeecff);
    transform: translateY(-18px);
}
.theme-item:nth-child(2){
    width: 50%;
}
.theme-item:nth-child(3) .theme-title{
    text-align: left;
}

.sty-line2 .dian{
    left: 0;
}
.search-list:hover{
  background: #eee;
}
.search-list{
  font-size: 16px;
  padding-left: 12px;
}
.dian{
    width: 10px;
    height: 10px;
    border-radius: 5px;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    background: #0069c9;
}
.sty-text{
    width: 240px;
}
.sty-line1{
    position: relative;
    width: calc(50% - 120px)
}
.line{
    width: 100%;
    height: 1px;
    background: #0069c9;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
}
.mt-40{
    margin-top: 40px;
}
.router-box {
    font-size: 18px;
    /* color: #fff; */
}
.router-box{
    line-height: 40px;
    text-align: center;

}
.bg-box{
    width: 34px;
    height: 34px;
    margin: 0 auto;
    /* background: -webkit-gradient(linear, top,  bottom, from(#0583e2), to(#006aca));
    background: linear-gradient(to bottom, #0583e2 0%, #006aca 100%);
    transform: skew(-45deg) translateY(-50%); */
    /* border-radius: 16px; */
    box-shadow: 0 0 2px #fff;
    cursor: pointer;
    /* background: url(../assets/img/hot-icon/1.png); */
}
.serve-meun-item:hover {
    box-shadow: 0 0 10px #66c5eb;
    cursor: pointer;
}
.serve-meun-item{
    position: relative;
    width: 15%;
    box-shadow: 0 0 5px #66c5eb;
    padding-top: 15px;
}
.serve-meun{
    display: flex;
    justify-content: space-between;
    margin-top:50px;
    margin-bottom: 20px;
    /* height: 97px; */
    /* background: url('../assets/img/menu-bg.png') no-repeat center center; */
}
.floor-title>span{
    letter-spacing: 12px;
    padding-bottom: 5px;
    border-bottom: 1px solid #3295f1;
}
.floor-title{
    color:#000;
    text-align: center;
    font-size: 40px;
    padding: 40px 0;
}
.pb-20{
    padding-bottom: 20px;
}
.hot-key>span~span:hover{
    color: #429ef3;
    cursor: pointer;
}
.hot-key>span:first-child{
    color:#2480ff;
}
.hot-key>span{
    color:#2480ff;
    font-size: 18px;
    padding:6px 12px;
}
.hot-key{
    width:100%;
    text-align: center;
    z-index: 10;
}
.container{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 15px 0 10px;
    /* width: 90%; */
}
.searchBox >>> .el-input.input2{
    height: 50px;
    box-sizing: border-box;
    border-color: #0069c9;
    font-size:24px;
    /* padding-left: 12px; */
}
.searchBox >>> .el-input__inner {
    border-color: #0169ca;
    height: 50px;
    line-height: 50px;
    padding-left: 24px;
    border-radius: 0;
    font-size: 16px;
    border: 0;
}
.searchBox >>> .el-button:hover{
    background: #458ce9;
    cursor: pointer;
}
.searchBox >>> .el-button {
    display: inline-block;
    width: 106px;
    height: 50px;
    border-radius: 25px;
    background: #367bff;
    color: #fff;
    vertical-align: top;
    font-size: 24px;
    text-align: center;
}
.searchBox{
    margin: 30px auto;
    box-shadow: 0 0 15px #ccc;
    border-radius: 40px;
    height: 80px;    
    position: relative;
    width: 50%;
    text-align: center;
    z-index: 20;
    opacity: 0.9;
    padding-top: 15px;
}
.search-box {
  width: calc(100% - 267px);
  box-shadow: 0 0 3px #999;
  background: #fff;
  border-radius: 5px;
  text-align: left;
  font-weight: bold;
  position: absolute;
  left: 25px;
  top: 55px;
  /* transform: translateX(-277px); */
  line-height: 32px;
}
.banner-title{
    position: absolute;
    top:160px;
    width: 100%;
    text-align: center;
    font-size: 70px;
    color:#fff;
    font-family: '隶书','楷体';
    z-index: 10;
}
.banner >>> .el-carousel__button{
    width: 52px;
    height: 10px;
}
.banner >>> .el-carousel__indicators--horizontal{
    left: 80%;
}
.banner3{
    height: 100%;
    background: url('../../public/img/banner3.jpg') center bottom no-repeat;
}
.banner2{
    height: 100%;
    background: url('../../public/img/banner2.jpg') center bottom no-repeat;
}
.banner1{
    height: 100%;
    background: url('../../public/img/banner1.jpg') center bottom no-repeat;
}
.banner{
    position:relative;
    height: 600px;
}
</style>