<template>
    <div class="business">
        <div class="classify">
            <ul class="tour-list">
                <li class="tour-item" :class="{act1:classify == '招商动态'}" @click="typeChange('招商动态')">
                    <div class="c-list">招商资讯</div> 
                </li>
                <li class="tour-item" :class="{act1:classify == '招商项目'}" @click="typeChange('招商项目')">
                    <div class="c-list">招商项目信息</div>
                </li>
                <li class="tour-item" :class="{act1:classify == '招商环境'}" @click="typeChange('招商环境')">
                    <div class="c-list">招商环境信息</div>
                </li>
            </ul>
        </div>
        <div class="main">
            <div class="left" @click="goDetail(primary.id,classify,JSON.stringify(params))">
                <div class="imgbox" >
                    <el-image fit="cover" style="width:100%;height:100%"  :src="primary.photoUrls.split(',')[0]"></el-image>
                </div>
                <div class="infos">
                    <div class="date">
                        <div class="day">{{primary.createdTime?primary.createdTime.substr(8,2):''}}</div>
                        <div class="year">{{primary.createdTime?primary.createdTime.substr(0,7):''}}</div>
                    </div>
                    <div class="info">
                        <div class="info-title ellipsis">
                            {{primary.title}}
                        </div>
                        <div class="info-text">{{regHtml(primary.content)}}</div>
                    </div>
                </div>
            </div>
            <div class="right">
                <div class="r-item" v-for="item in ZSDTdata" :key="item.id" @click="goDetail(item.id,classify,JSON.stringify(params))" @mouseenter="hanldItem(item.id)" :class="{active:isact == item.id}">
                    <div class="r-infos">
                        <div class="r-title">{{item.title}}</div>
                        <div class="r-info">{{regHtml(item.content)}}</div>
                    </div>
                    <div class="r-date" v-show="isact == item.id">
                        <div class="r-day">{{item.createdTime?item.createdTime.substr(8,2):''}}</div>
                        <div class="r-year">{{item.createdTime?item.createdTime.substr(0,7):''}}</div>
                    </div>
                    <div class="r-date" v-show="isact != item.id">
                        {{item.createdTime&&item.createdTime.substr(0,10)}}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {GetList} from '@/api/other.js'
import {regHtml} from '@/utils/reg'
export default {
    computed:{
        regHtml:()=>regHtml,
    },
    data(){
        return{
            bList:['招商动态','招商环境','办事指南','招商项目','政务公开'],
            businessT1:['年产1万件锂电池壳体项目','年产2亿平方米锂离子电池用隔膜项目','年产5GW太阳能光伏电池及组件项目','塑料包装生产项目','年产6000吨氢氧化锂项目'],
            businessT2:['食用菌种植加工项目引资','天然有机燕麦深加工建设引资','建筑外墙铝合金幕墙生产项目引资','外企产业转移项目投资公司名单','南黄泽库县近期建设引资'],
            businessT3:[
                {title:'万华超纤 股份融资 公告',img:require('../../../public/img/business-s1.png')},
                {title:'20亿投资 高密度柔 性电池项 目',img:require('../../../public/img/business-s2.png')},
                {title:'聚合物理 电池项目',img:require('../../../public/img/business-s3.png')},
                {title:'福禄养生 主题森林 旅游项目',img:require('../../../public/img/business-s4.png')},
                {title:'西藏拉萨 柳唔新区',img:require('../../../public/img/business-s5.png')},
            ],
            ZSDTparams:{//招商动态
                currentPage: 1,
                filter: {infoType:257023242199109,recommendTypes:'首页'},
                pageSize: 6,
            },
            ZSDTdata:[{},{},{},{},{},{}],
            ZSXMparams:{//招商项目
                currentPage: 1,
                filter: {infoType:257023606935621,recommendTypes:'首页'},
                pageSize: 6,
            },
            ZSXMdata:[{}],
            ZSHJparams:{//招商环境
                currentPage: 1,
                filter: {infoType:257027635433541,recommendTypes:'首页'},
                pageSize: 6,
            },
            ZSHJdata:[{}],
            params:{filter:{}},
            classify:'招商动态',
            isact:'0',
            primary:{photoUrls:'',createdTime:'',content:''}
        }
    },
    methods:{
        hanldItem(k){
            this.isact = k;
            
        },
        typeChange(type){
            this.classify = type;
            this.getPageData();
        },
        getPageData(){
            switch(this.classify){
                case '招商资讯':
                    this.params = this.ZSDTparams;
                    break;
                case '招商项目':
                    this.params = this.ZSXMparams;
                    break;
                case '招商环境':
                    this.params = this.ZSHJparams;
                    break;
                default:
                    this.params = this.ZSDTparams;
            };
            GetList(this.params).then(res=>{
                this.primary = res.data.data.list[0];
                this.ZSDTdata = res.data.data.list.splice(1,6);
                this.isact = this.ZSDTdata[0].id;
            })
        },
        getZSXMdata(){
            GetList(this.ZSXMparams).then(res=>{
                this.ZSXMdata = res.data.data.list;
            })
        },
        getZSHJdata(){
            GetList(this.ZSHJparams).then(res=>{
                this.ZSHJdata = res.data.data.list;
            })
        },
        goDetail(id,type,oParams){
            this.$router.push({path:'/detail',query:{id,type,oParams}})
        },
    },
    mounted(){
        this.getPageData()
    }
}
</script>
<style scoped>
.active .r-info{
    height: auto;
    cursor: pointer;
}
.active{
    cursor: pointer;
}
.r-date{
    width: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #666;
}

.r-infos{
    width: calc(100% - 130px);
}
.r-day{
    font-size: 40px;
}
.r-item{
    display: flex;
    justify-content: space-between;
}
.r-title{
    font-size: 16px;
    font-weight: bold;
    line-height: 42px;
    border-bottom: 1px solid #ccc;
}
.r-info{
    display:-webkit-box;
    overflow:hidden;
    text-overflow:ellipsis;
    -webkit-line-clamp:3;
    -webkit-box-orient:vertical;
    line-height: 24px;
    font-size: 14px;
    color: #666;
    margin: 10px 0 20px ;
    height: 0;
    transition: height 0.8s;
}
.main{
    display: flex;
    justify-content: space-between;
    height: 480px;
    overflow: hidden;
}
.left{
    width: 530px;
    height: 100%;
    cursor: pointer;
}
.info{
    width: calc(100% - 90px);
    padding: 20px;
    color: #fff;
}
.info-text{
    line-height: 24px;
    font-size: 14px;
    margin-top: 10px;
     display:-webkit-box;
    overflow:hidden;
    text-overflow:ellipsis;
    -webkit-line-clamp:3;
    -webkit-box-orient:vertical;
}
.info-title{
    font-size: 20px;
    line-height: 36px;
    border-bottom: 1px solid #fff;
}
.infos{
    height: 168px;
    background: rgba(57, 122, 247);
    display: flex;
    justify-content: space-between;
}
.date{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    color: #fff;
    width: 90px;
}
.day{
    font-size: 42px;
}
.year{
    line-height: 20px;
}
.imgbox{
    height: 312px;
}
.right{
    width: calc(100% - 602px);
    height: 100%;
    padding: 20px 0;
}
.tour-list{
    display: flex;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
}
.tour-item{
    width: 124px;
    height: 42px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
}
.tour-item>div{
    width: 124px;
    /* height: 70px; */
    /* background: #0069c9; */
    text-align: center;; 
    box-sizing: border-box;
    position: relative;
    transition: all 0.2s;
}
.tour-item>div:not(.tour-item:last-child>div){
    border-right: 1px solid #ccc;
}
.tour-item.act1,.tour-item:hover{
    background: #0069c9;
    cursor: pointer;
    color: #fff;
}
.tour-item.act1>div.c-list,.tour-item:hover .c-list{
    border-color: #0069c9 !important;
    /* border-right: 1px solid transparent; */
}

</style>