import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: sessionStorage.getItem('user.token'),
    id: sessionStorage.getItem('user.id'),
    nickName: sessionStorage.getItem('user.nickName'),
    avatar: sessionStorage.getItem('user.avatar'),
    userName: sessionStorage.getItem('user.userName'),
    version: sessionStorage.getItem('user.version'),
    lonR:(+process.env.VUE_APP_LONR)*(+process.env.VUE_APP_NNN),
    latR:(+process.env.VUE_APP_LATR)*(+process.env.VUE_APP_NNN),
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      if (token) {
        state.token = token
        sessionStorage.setItem('user.token', token)
      } else {
        state.token = null
        sessionStorage.removeItem('user.token')
      }
    },
    SET_avatar: (state, avatar) => {
      if (avatar) {
        state.avatar = avatar
        sessionStorage.setItem('user.avatar', avatar)
      } else {
        state.avatar = null
        sessionStorage.removeItem('user.avatar')
      }
    },
    SET_nickName: (state, nickName) => {
      if (nickName) {
        state.nickName = nickName
        sessionStorage.setItem('user.nickName', nickName)
      } else {
        state.nickName = null
        sessionStorage.removeItem('user.nickName')
      }
    },
    SET_userName: (state, userName) => {
      if (userName) {
        state.userName = userName
        sessionStorage.setItem('user.userName', userName)
      } else {
        state.userName = null
        sessionStorage.removeItem('user.userName')
      }
    },
    SET_ID: (state, id) => {
      if (id) {
        state.id = id
        sessionStorage.setItem('user.id', id)
      } else {
        state.id = null
        sessionStorage.removeItem('user.id')
      }
    },
    SET_version: (state, version) => {
      if (version||version === 0) {
        state.version = version
        sessionStorage.setItem('user.version', version)
      } else {
        state.version = null
        sessionStorage.removeItem('user.version')
      }
    },
  },
  actions: {
    loginOut(context){
      return new Promise((res,rej)=>{
          context.commit('SET_TOKEN',null);
          context.commit('SET_avatar',null);
          context.commit('SET_nickName',null);
          context.commit('SET_userName',null);
          context.commit('SET_ID',null);
          context.commit('SET_version',null);
          res();
      })   
  }
  },
  modules: {
  }
})
