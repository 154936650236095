<template>
    <div class="sidebar">
        <ul >
            <li class="sidebar-item" title="去登陆" v-if="!token">
                <router-link to="/login">
                    <span class="tologin">登录注册</span> 
                </router-link>
            </li>
            <li class="sidebar-item user" v-if="token" @click="show = true">
                <!-- <el-popover placement="left" width="400" trigger="click">
                    <div>

                    </div>
                    <div class="innerbox" slot="reference">
                        
                    </div>
                </el-popover> -->
                <el-avatar shape="square" :size="60"  :src="avatar"></el-avatar>
                <div class="nickName ellipsis">{{nickName}}</div>
            </li>
            <li class="sidebar-item lo" v-if="token" @click="quit" title="登出">
                <span class="loginout">退出</span>
            </li>
            <!-- <li class="sidebar-item"><span class="iconfont icon-weixin"></span></li>
            <li class="sidebar-item"><span class="iconfont icon-shouji"></span></li>
            <li class="sidebar-item"><span class="iconfont icon-xiaochengxu"></span></li>
            <li class="sidebar-item"><span class="iconfont icon-youjian"></span></li>
            <li class="sidebar-item"><span class="iconfont icon-kefu"></span></li>
            <li class="sidebar-item"><span class="iconfont icon-erweima1"></span></li> -->
            <li class="sidebar-item" v-show="showBtn" title="回顶部" @click="backTop"><span class="iconfont icon-fanhuidingbu"></span></li>
        </ul>
        <user-station :show="show" @close="clsoeUser"></user-station>
    </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import userStation from '@/components/user/userStation'
export default {
    components:{userStation},
    data(){
        return{
            show:false,
            showBtn:false,
        }
    },
    computed:{
        ...mapState({
            token:state => state.token,
            avatar:state => state.avatar||require('@/assets/img/avatar.png'),
            nickName:state => state.nickName
        })
    },
    methods:{
        clsoeUser(){this.show = false},
        ...mapActions(['loginOut']),
        oShowbtn() {
            let that = this;
            let scrollTop =
                window.pageYOffset ||
                document.documentElement.scrollTop ||
                document.body.scrollTop;
            that.scrollTop = scrollTop;
            if (scrollTop > 750) {
                this.showBtn = true;
            } else {
                this.showBtn = false;
            }
        },
        quit(){
            this.loginOut().then(()=>{
                this.$message({
                    message:'退出成功',
                    type:'success'
                })
            })
        },
        backTop() {
            var timer = setInterval(function () {
                let osTop =
                    document.documentElement.scrollTop || document.body.scrollTop;
                let ispeed = Math.floor(-osTop / 5);
                document.documentElement.scrollTop = document.body.scrollTop =
                    osTop + ispeed;
                this.isTop = true;
                if (osTop === 0) {
                    clearInterval(timer);
                }
            }, 30);
        },
    },
    mounted(){
        window.addEventListener("scroll", this.oShowbtn, true);
    }
}
</script>
<style scoped>
.innerbox{
    width: 100%;
    height: 100%;
    position: relative;
}
.nickName{
    position: absolute;
    left: 50%;
    bottom: 0;
    width: 100px;
    transform: translateX(-50%);
    color: #314849;
}
.sidebar-item.lo{
    margin-top:3px !important;
}
.sidebar-item.user{
    height: 80px;
    line-height: 20px;
    background: transparent;
    position: relative;
    padding: 0 !important;
}
.sidebar-item:hover{
    background: #e9ddb3;
}
.loginout{
    color: #909399;
    font-size: 20px;
}
.tologin{
    font-size: 20px;
    color:#9c7c03;
}
.sidebar-item>span.iconfont{
    font-size: 32px;
    color:#141414;
}
.sidebar-item{
    width: 60px;
    height: 60px;
    line-height: 60px;
    border-radius: 6px;
    background: #eee;
    text-align: center;
    cursor: pointer;
}
.sidebar-item:first-child{
    line-height: 22px;
    padding:8px 10px;
    box-sizing: border-box;
}
.sidebar-item+.sidebar-item{
    margin-top:10px;
}
.sidebar{
    width: 60px;
    position: fixed;
    top:55%;
    right: 20px;
    z-index: 20;
    transform: translateY(-50%);
}
</style>