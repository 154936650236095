import axios from '@/utils/axios';
export function GetZSDTinfos(data){
    /**
     * 招商动态 数据获取 
     **/
    return axios({
        method:'post',
        url: "/api/xnsxinfo/information/getpageforweb",
        data,
    })
}
export function GetEnterpriseInfos(data){
    /**
     * 企业信息 数据获取 
     **/
    return axios({
        method:'post',
        url: "/api/enterprise/enterprise/getpageforweb",
        data,
    })
}
export function GetOneEnterpriseInfos(params){
    /**
     * 企业信息单条 数据获取 
     **/
    return axios({
        method:'get',
        url: "/api/enterprise/enterprise/getforweb",
        params,
    })
}
export function GetEnterpriseHonor(data){
    /**
     * 企业荣誉信息 数据获取 
     **/
    return axios({
        method:'post',
        url: "/api/enterprise/enterprisehonor/getpageForWeb",
        data,
    })
}
export function GetOneEnterpriseHonor(params){
    /**
     * 企业荣誉单条 数据获取 
     **/
    return axios({
        method:'get',
        url: "/api/enterprise/enterprisehonor/get",
        params,
    })
}
export function GetXnrecruitInfos(data){
    /**
     * 乡宁直聘 数据获取 
     **/
    return axios({
        method:'post',
        url: "/api/enterprise/xnrecruit/getpageForWeb",
        data,
    })
}
export function GetOneXnrecruitInfos(id){
    /**
     * 乡宁直聘单条 数据获取 
     **/
    return axios({
        method:'get',
        url: "/api/enterprise/xnrecruit/getForWeb",
        params:{id:id},
    })
}