import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from '@/utils/axios';
import ElementUI from 'element-ui'
import BaiduMap from 'vue-baidu-map'
import arcMap from '@/components/arcMap.vue'
import '@/assets/styles/reset.css'
import '@/assets/iconfont/iconfont.css'
import 'element-ui/lib/theme-chalk/index.css';
import * as echarts from 'echarts';
import VueVideoPlayer from 'vue-video-player'
import 'video.js/dist/video-js.css'
import '@/assets/icons';

Vue.config.productionTip = false

Vue.prototype.axios = axios;
Vue.prototype.echart = echarts;
Vue.prototype.$bus = new Vue() 

Vue.use(ElementUI);
Vue.use(VueVideoPlayer);
Vue.use(BaiduMap, { ak: '5cqjepMbAbxVyGPO42u1jOObddTaxuuj' })
Vue.component('arcMap',arcMap)
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')