import axios from '@/utils/axios';
export function rskSubmit(data) {
    /**
     * 求租信息 提交审核 
     **/
    return axios({
        method: 'put',
        url: "/api/Resource/RentSeeking/Submit",
        data,
    })
}
export function GetRentInfos(data) {
    /**
     * 出租信息 数据获取 
     **/
    return axios({
        method: 'post',
        url: "/api/resource/rentinfo/GetPageForWeb",
        data,
    })
}
export function GetOneRentInfos(id) {
    /**
     * 出租信息单条 数据获取 
     **/
    return axios({
        method: 'get',
        url: "/api/resource/rentinfo/getForWeb",
        params:{id:id},
    })
}
export function GetRentseeking(data) {
    /**
     * 求租信息 数据获取 
     **/
    return axios({
        method: 'post',
        url: "/api/resource/rentseeking/getpage",
        data,
    })
}
export function GetRentseekingForweb(data) {
    /**
     * 求租信息 数据获取 
     **/
    return axios({
        method: 'post',
        url: "/api/resource/rentseeking/getpageForWeb",
        data,
    })
}
export function RentseekingAdd(data) {
    /**
     * 新增求租信息 
     **/
    return axios({
        method: 'post',
        url: "/api/resource/rentseeking/add",
        data,
    })
}
export function RentseekingDelete(id) {
    /**
     * 删除求租信息 
     **/
    return axios({
        method: 'delete',
        url: "/api/resource/rentseeking/SoftDelete",
        params:{id},
    })
}
export function GetRentdone(data) {
    /**
     *  成交信息 数据获取 
     **/
    return axios({
        method: 'post',
        url: "/api/resource/rentdone/getpage",
        data,
    })
}
export function GetRentdoneV2(data) {
    /**
     *  成交信息 数据获取 V2
     **/
    return axios({
        method: 'post',
        url: "/api/resource/rentdone/getpageforwebv2",
        data,
    })
}
export function GetEnterpriseLand(data){
    /**
     * 企业土地 数据获取 
     **/
    return axios({
        method:'post',
        url: "/api/enterprise/enterpriseland/getpageForWeb",
        data,
    })
}
export function GetOneEnterpriseLand(params){
    /**
     * 企业土地单条 数据获取 
     **/
    return axios({
        method:'get',
        url: "/api/enterprise/enterpriseland/getForweb",
        params,
    })
}
export function GetOneEnterpriseNeedzj(params) {
    /**
     * 资金需求单条 数据获取 
     **/
    return axios({
        method: 'get',
        url: "/api/enterprise/enterpriseneedzj/getForWeb",
        params,
    })
}
export function GetEnterpriseNeedzj(data) {
    /**
     * 资金需求信息 数据获取 
     **/
    return axios({
        method: 'post',
        url: "/api/enterprise/enterpriseneedzj/getpageForWeb",
        data,
    })
}
export function GetOneEnterpriseNeedxm(params) {
    /**
     * 项目需求单条 数据获取 
     **/
    return axios({
        method: 'get',
        url: "/api/enterprise/enterpriseneedxm/getForWeb",
        params,
    })
}
export function GetEnterpriseNeedxm(data) {
    /**
     * 项目需求信息 数据获取 
     **/
    return axios({
        method: 'post',
        url: "/api/enterprise/enterpriseneedxm/getpageForWeb",
        data,
    })
}
export function GetEnterprises(data) {
    /**
     * 企业信息  全部
     **/
    return axios({
        method: 'post',
        url: "/api/enterprise/enterprise/getpageforweb",
        data,
    })
}
export function GetOneEnterprise(params) {
    /**
     * 企业信息单条 
     **/
    return axios({
        method: 'get',
        url: "/api/enterprise/enterprise/getforweb",
        params,
    })
}
export function GetRentDoneFw(){
    /**
     * 获取房屋交易统计 
     **/
     return axios({
        method: 'post',
        url: "/api/XnsxInfo/Statistics/StatRentDoneFw",
    })
}
export function GetRentDoneTd(){
    /**
     * 获取土地交易统计 
     **/
     return axios({
        method: 'post',
        url: "/api/XnsxInfo/Statistics/StatRentDoneTd",
    })
}