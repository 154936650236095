<template>
  <div id="app">
    <router-view/>
    <footer-e v-if="!nofoot.includes($route.name)"></footer-e>
		<dialogLogin :showLogin="showLogin" @close="showColse"></dialogLogin>
    <o-sidebar></o-sidebar>
  </div>
</template>
<script>
import dialogLogin from '@/components/dialogLogin'
import footerE from './components/footer.vue'
import oSidebar from '@/components/sidebar'
export default {
  components: { footerE ,dialogLogin,oSidebar},
  data(){
    return{
      nofoot:['login','signIn'],
      showLogin:false
    }
  },
  mounted(){
    this.$bus.$on('showLogin',()=>{this.showLogin = true})
  },
  created(){
    // Array.includes
  },
  methods:{
    showColse(){this.showLogin = false}
  }
  // components:{oNav,oSidebar}
}
</script>
<style>
#app {
  font-family: '微软雅黑','宋体',Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #141414;
}
.mt-20{
  margin-top:20px;
}
.mt-10{
  margin-top:10px;
}
.w-1200{
  width: 1200px;
  margin: 20px auto;
}
.w-1280{
  width: 1280px;
  margin: 20px auto 0;
}
.videotag{
  object-fit: cover;
}
.c-666{
  color: #666;
}
.pagination{
  text-align: center;
}
::-webkit-scrollbar {
  width: 0px; /* 设置滚动条的宽度 */
}
 
::-webkit-scrollbar-thumb {
  background-color: #888; /* 设置滚动条滑块的颜色 */
  border-radius: 0px; /* 设置滚动条滑块的圆角 */
}
 
::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* 设置滚动条滑块在悬停时的颜色 */
}
 
::-webkit-scrollbar-track {
  background-color: #eee; /* 设置滚动条背景的颜色 */
}
</style>
