import axios from '@/utils/axios';
export function GetXnInformation(data) {
    /**
     * 咨询详情
     **/
    return axios({
        method: 'post',
        url: "/api/XnsxInfo/Information/GetPageForWeb",
        data,
    })
}


// 乡宁荟/乡宁游/景点
export function GetScenerySpotPage(data) {
    return axios({
        method: 'post',
        url: "/api/village/ScenerySpot/getpageforweb",
        data: data
    })
}
export function GetScenerySpot(id) {
    return axios({
        method: 'get',
        url: "/api/village/ScenerySpot/getforweb",
        params: { id: id }
    })
}

// 乡宁荟/资源集市
export function GetHousePage(data) {
    return axios({
        method: 'post',
        url: "/api/Resource/RentInfo/GetPageForWeb",
        data: data
    })
}
export function GetHouse(id) {
    return axios({
        method: 'get',
        url: "/api/Resource/RentInfo/getForWeb",
        params: { id: id }
    })
}

// 乡宁荟/乡宁游/美丽乡村
export function GetVillagePage(data) {
    return axios({
        method: 'post',
        url: "/api/village/village/getpageforweb",
        data: data
    })
}
export function GetVillage(id) {
    return axios({
        method: 'get',
        url: "/api/village/village/getforweb",
        params: { id: id }
    })
}

// 乡宁荟/乡宁游/旅游线路
export function GetTouristRoutesPage(data) {
    return axios({
        method: 'post',
        url: "/api/village/touristroutes/getpageforweb",
        data: data
    })
}
export function GetTouristRoutes(id) {
    return axios({
        method: 'get',
        url: "/api/village/touristroutes/getforweb",
        params: { id: id }
    })
}
export function GetTouristPointsPage(data) {
    return axios({
        method: 'post',
        url: "/api/village/touristpoints/getpageforweb",
        data: data
    })
}
export function GetTouristPointsPageByDay(data) {
    return axios({
        method: 'post',
        url: "/api/village/touristpoints/getpagebyday",
        data: data
    })
}
export function GetTouristPoints(id){
    return axios({
        method:'get',
        url:"/api/village/touristpoints/get",
        params:{id:id}
    })
}

// 乡宁荟/共享农庄/共享农庄
export function GetSharedFarmPage(data) {
    return axios({
        method: 'post',
        url: "/api/village/sharedfarm/getpageforweb",
        data: data
    })
}
export function GetSharedFarmPageV2(data) {
    return axios({
        method: 'post',
        url: "/api/village/sharedfarm/getpageforwebv2",
        data: data
    })
}
export function GetSharedFarm(id) {
    return axios({
        method: 'get',
        url: "/api/village/sharedfarm/getforweb",
        params: { id: id }
    })
}
export function GetYWXM(id) { //字典获取游玩项目
    return axios({
        method: 'post',
        url: "api/admin/dictionary/getPage",
        params: { dictionaryTypeId: id }
    })
}
// 乡宁荟/乡宁购/农特产品
export function GetProductPage(data) {
    return axios({
        method: 'post',
        url: "/api/village/product/getpageforweb",
        data: data
    })
}
export function GetProductPageV2(data) {
    return axios({
        method: 'post',
        url: "/api/village/product/getpageforwebv2",
        data: data
    })
}
export function GetProduct(id) {
    return axios({
        method: 'get',
        url: "/api/village/product/get",
        params: { id: id }
    })
}
export function GetProductList(id){
    return axios({
        method:'get',
        url:"/api/village/product/getforweb",
        params:{id:id}
    })
}
// 乡宁荟/乡宁购/特色小吃
export function GetFoodPage(data) {
    return axios({
        method: 'post',
        url: "/api/village/food/getpageforweb",
        data: data
    })
}
export function GetFoodPageV2(data) {
    return axios({
        method: 'post',
        url: "/api/village/food/getpageforwebv2",
        data: data
    })
}
export function GetFood(id) {
    return axios({
        method: 'get',
        url: "/api/village/food/get",
        params: { id: id }
    })
}
export function GetFoodFarm(id){
    return axios({
        method:'get',
        url:"/api/village/food/getforweb",
        params:{id:id}
    })

}
// 乡宁荟/点赞 --

// 乡宁荟/评价 --
export function GetEvaluationPage(data) {
    return axios({
        method: 'post',
        url: "/api/village/evaluation/getpage",
        data: data
    })
}
export function GetEvaluation(id) {
    return axios({
        method: 'get',
        url: "/api/village/evaluation/get",
        params: { id: id }
    })
}
export function AddEvaluation(data) {
    return axios({
        method: 'post',
        url: "/api/village/evaluation/add",
        data: data
    })
}
export function UpdateEvaluation(data) {
    return axios({
        method: 'put',
        url: "/api/village/evaluation/update",
        data: data
    })
}
export function SoftDeleteEvaluation(id) {
    return axios({
        method: 'delete',
        url: "/api/village/evaluation/softdelete",
        params: { id: id }
    })
}
export function BatchSoftDeleteEvaluation(data) {
    return axios({
        method: 'put',
        url: "/api/village/evaluation/batchsoftdelete",
        data: data
    })
}
// 乡宁情/人才档案/（复用）乡贤能人基本情况摸底表
export function GetCapablePersonPage(data) {
    return axios({
        method: 'post',
        url: "/api/information/capableperson/getpage",
        data: data
    })
}
export function GetCapablePerson(id) {
    return axios({
        method: 'get',
        url: "/api/information/capableperson/get",
        params: { id: id }
    })
}
// 乡宁旺/企业展示/企业信息
export function GetEnterprisePage(data) {
    return axios({
        method: 'post',
        url: "/api/enterprise/enterprise/getpageforweb",
        data: data
    })
}
export function GetEnterprise(id) {
    return axios({
        method: 'get',
        url: "/api/enterprise/enterprise/getforweb",
        params: { id: id }
    })
}
// 乡宁旺/乡宁直聘
export function GetXnRecruitPage(data) {
    return axios({
        method: 'post',
        url: "/api/enterprise/xnrecruit/getpageForWeb",
        data: data
    })
}
export function GetXnRecruit(id) {
    return axios({
        method: 'get',
        url: "/api/enterprise/xnrecruit/get",
        params: { id: id }
    })
}
// 资源集市/出租信息
export function GetRentInfoPage(data) {
    return axios({
        method: 'post',
        url: "/api/resource/rentinfo/GetPageForWeb",
        data: data
    })
}
export function GetRentInfo(id) {
    return axios({
        method: 'get',
        url: "/api/resource/rentinfo/getForWeb",
        params: { id: id }
    })
}
// 资源集市/求租信息
export function GetRentSeekingPage(data) {
    return axios({
        method: 'post',
        url: "/api/resource/rentseeking/getpage",
        data: data
    })
}
export function GetRentSeeking(id) {
    return axios({
        method: 'get',
        url: "/api/resource/rentseeking/get",
        params: { id: id }
    })
}
// 资源集市/成交信息
export function GetRentDonePage(data) {
    return axios({
        method: 'post',
        url: "/api/resource/rentdone/getpage",
        data: data
    })
}
export function GetRentDone(id) {
    return axios({
        method: 'get',
        url: "/api/resource/rentdone/get",
        params: { id: id }
    })
}
// 资源集市/建议信箱
export function GetSuggestionPage(data) {
    return axios({
        method: 'post',
        url: "/api/resource/suggestion/getpage",
        data: data
    })
}
export function GetSuggestion(id) {
    return axios({
        method: 'get',
        url: "/api/resource/suggestion/get",
        params: { id: id }
    })
}
export function AddSuggestion(data) {
    return axios({
        method: 'post',
        url: "/api/resource/suggestion/add",
        data: data
    })
}
export function UpdateSuggestion(data) {
    return axios({
        method: 'put',
        url: "/api/resource/suggestion/update",
        data: data
    })
}
export function SoftDeleteSuggestion(id) {
    return axios({
        method: 'delete',
        url: "/api/resource/suggestion/softdelete",
        params: { id: id }
    })
}
export function BatchSoftDeleteSuggestion(data) {
    return axios({
        method: 'put',
        url: "/api/resource/suggestion/batchsoftdelete",
        data: data
    })
}
// 公共模块/资讯详情
export function GetInformationPage(data) {
    return axios({
        method: 'post',
        url: "/api/xnsxinfo/information/getpageforweb",
        data: data
    })
}
export function GetInformation(id) {
    return axios({
        method: 'get',
        url: "/api/xnsxinfo/information/get",
        params: { id: id }
    })
}
// 公共模块/浏览信息 --

// 公共模块/评论 --
export function GetCommentPage(data) {
    return axios({
        method: 'post',
        url: "/api/xnsxinfo/comment/getpage",
        data: data
    })
}
export function GetComment(id) {
    return axios({
        method: 'get',
        url: "/api/xnsxinfo/comment/get",
        params: { id: id }
    })
}
export function AddComment(data) {
    return axios({
        method: 'post',
        url: "/api/xnsxinfo/comment/add",
        data: data
    })
}
export function UpdateComment(data) {
    return axios({
        method: 'put',
        url: "/api/xnsxinfo/comment/update",
        data: data
    })
}
export function SoftDeleteComment(id) {
    return axios({
        method: 'delete',
        url: "/api/xnsxinfo/comment/softdelete",
        params: { id: id }
    })
}
export function BatchSoftDeleteComment(data) {
    return axios({
        method: 'put',
        url: "/api/xnsxinfo/comment/batchsoftdelete",
        data: data
    })
}
// 公共模块/补贴申请
export function GetSubsidyApplyPage(data) {
    return axios({
        method: 'post',
        url: "/api/xnsxinfo/subsidyapply/getpage",
        data: data
    })
}
export function GetSubsidyApply(id) {
    return axios({
        method: 'get',
        url: "/api/xnsxinfo/subsidyapply/get",
        params: { id: id }
    })
}
export function AddSubsidyApply(data) {
    return axios({
        method: 'post',
        url: "/api/xnsxinfo/subsidyapply/add",
        data: data
    })
}
export function UpdateSubsidyApply(data) {
    return axios({
        method: 'put',
        url: "/api/xnsxinfo/subsidyapply/update",
        data: data
    })
}
export function SoftDeleteSubsidyApply(id) {
    return axios({
        method: 'delete',
        url: "/api/xnsxinfo/subsidyapply/softdelete",
        params: { id: id }
    })
}
export function BatchSoftDeleteSubsidyApply(data) {
    return axios({
        method: 'put',
        url: "/api/xnsxinfo/subsidyapply/batchsoftdelete",
        data: data
    })
}
export function viewInfoAdd(data) {
    return axios({
        method: 'post',
        url: "/api/XnsxInfo/ViewInfo/Add",
        data: data
    })
}
