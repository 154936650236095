import axios from '@/utils/axios';
export function GetTalents(data) {
    /**
     * 能人介绍 数据获取 
     **/
    return axios({
        method: 'post',
        url: "/api/xnsxinfo/information/getpageforweb",
        data,
    })
}
export function GetDetail(id) {
    /**
     * 资讯类 详情
     **/
    return axios({
        method: 'get',
        url: "/api/xnsxinfo/information/GetForWeb",
        params: { id },
    })
}
// 视频管理
export function GetVideoInfoPage(data) {
    return axios({
        method: 'post',
        url: "/api/xnsxinfo/VideoInfo/GetPageForWeb",
        data: data
    })
}
export function GetVideoInfo(id) {
    return axios({
        method: 'get',
        url: "/api/xnsxinfo/VideoInfo/get",
        params: { id: id }
    })
}

// 人才档案
export function GetPersonnelfilePage(data) {
    return axios({
        method: 'post',
        url: "/api/xnsxinfo/Personnelfile/getpageforweb",
        data: data
    })
}
export function GetPersonnelfile(id) {
    return axios({
        method: 'get',
        url: "/api/xnsxinfo/Personnelfile/getforweb",
        params: { id: id }
    })
}