<!--个人中心-->
<template>
    <el-dialog title="个人中心" :visible.sync="showdig" class="acc_dlg" width="420px" append-to-body center @close="clearDig">
            <div class="acc-tab">
                <el-tabs v-model="activeName" >
                    <el-tab-pane label="个人资料" name="second">
                        <div>
                            <el-form label-width="90px" :model="formUser" :rules="rules" ref="ruleForm" class="user_form">
                                <el-form-item label="用户名" prop="userName">
                                    <el-input v-model="formUser.userName" size="small" disabled></el-input>
                                </el-form-item>
                                <el-form-item label="头像" class="ed-avatar">
                                    <el-upload
                                        class="avatar-uploader"
                                        :action="'/api/admin/user/avatarupload'"
                                        :show-file-list="false"
                                        :http-request="av_upload"
                                        :on-success="handleAvatarSuccess"
                                        :before-upload="beforeAvatarUpload">
                                        <img v-if="imageUrl" :src="imageUrl" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                    </el-upload>
                                </el-form-item>
                                <el-form-item label="昵称" prop="nickName">
                                    <el-input v-model="formUser.nickName" size="small"></el-input>
                                </el-form-item>
                            </el-form>
                            <div style="text-align:center">
                                <el-button size="small" type="primary" @click="submitEidt('ruleForm')" :loading="loading">确定</el-button>
                                <!-- <el-button size="small" type="info">取消</el-button> -->
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="修改密码" name="third">
                        <el-form ref="passForm" label-width="100px" :model="passForm" :rules="passRules">
                            <el-form-item label="原密码" prop="oldPassword" style="width:320px">
                                <el-input v-model="passForm.oldPassword" show-password size="small"></el-input>
                            </el-form-item>
                            <el-form-item label="新密码" prop="newPassword" style="width:320px">
                                <el-input v-model="passForm.newPassword" show-password size="small"></el-input>
                            </el-form-item>
                            <el-form-item label="确认新密码" prop="newPassword1" style="width:320px">
                                <el-input v-model="passForm.newPassword1" show-password size="small"></el-input>
                            </el-form-item>
                        </el-form>
                        <div style="text-align:center">
                            <el-button size="small" type="primary" @click="submitPass('passForm')" :loading="loadingPass">确定</el-button>
                            <!-- <el-button size="small" type="info">取消</el-button> -->
                        </div>
                    </el-tab-pane>
                </el-tabs>
            </div>
            <!-- <div slot="footer" class="dialog-footer" >
                <el-button @click="dialogVisible_user = false">取 消</el-button>
                <el-button type="primary" @click="dialogVisible_user = false">确 定</el-button>
            </div> -->
        </el-dialog>
</template>

<script>
import { mapState , mapMutations} from 'vuex'
import {Getbasic} from '@/api/signIn'
export default {
    props:{
        show:Boolean,
    },
    watch:{
        show(a){
            this.showdig = a;
        },
        oformUser:{
            handler:function(a){
                this.formUser = {...a};
                this.imageUrl = a.avatar;
            },
            deep:true,
            // immediate:true,
        }
    },
    data(){
        var validatePass = (rule, value, callback) => {
          if (value === '') {
            callback(new Error('请输入密码'));
          } else {
            if (this.passForm.newPassword1) {
              console.log(this.passForm.newPassword1);
              this.$refs.ruleForm.validateField('newPassword1');
            }
            callback();
          }
        };
        var validatePass2 = (rule, value, callback) => {
          if (value === '') {
            callback(new Error('请再次输入密码'));
          } else if (value !== this.passForm.newPassword) {
            callback(new Error('两次输入密码不一致!'));
          } else {
            callback();
          }
        };
        return{
            activeName:'second',
            passForm:{},
            rules:{
                nickName:[
                    { required: true, message: '昵称不可为空', trigger: 'blur' },
                ],
            },
            passRules:{
                oldPassword:[
                    { required: true, message: '请输入原密码', trigger: 'blur' },
                ],
                newPassword:[
                    { validator: validatePass, trigger: 'blur' },
                    {required: true,trigger: 'blur',message:'请输入密码'}
                ],
                newPassword1:[
                    { validator: validatePass2, trigger: 'blur' },
                    {required: true,trigger: 'blur',message:'请输入密码'}
                ],
            },
            loadingPass:false,
            imageUrl:'',
            loading:false,
            formUser:{},
            showdig:false,
        }
    },
    computed:{
        ...mapState({
            oformUser:state => { return {userName:state.userName,nickName:state.nickName,avatar:state.avatar,id:state.id,version:state.version}},
        })
    },
    created(){
        this.formUser = {...this.oformUser};
        this.imageUrl = this.formUser.avatar;
        // console.log(this.formUser);
    },
    methods:{
        ...mapMutations(['SET_TOKEN','SET_ID','SET_avatar','SET_nickName','SET_userName','SET_version']),
        clearDig(){
            this.$emit('close')
        },
        submitEidt(fromName){
            this.$refs[fromName].validate((a)=>{
                if(a){
                    this.loading = true;
                    const data = {id:this.formUser.id,avatar:this.imageUrl,nickName:this.formUser.nickName,version:this.formUser.version}
                    this.axios({
                        method:"put",
                        url:'/api/Admin/User/UpdateBasic',
                        data:data,
                    }).then(res=>{
                        if(res.data.code == 1){
                            this.$message({
                                message:'操作成功',
                                type:'success'
                            })
                            Getbasic().then(res=>{
                                this.SET_ID(res.data.data.id)
                                this.SET_avatar(res.data.data.avatar)
                                this.SET_nickName(res.data.data.nickName)
                                this.SET_version(res.data.data.version)
                            })
                            this.loading = false;
                        }else{
                            this.$message({
                                message:res.data.message,
                                type:'error',
                            })
                        }
                    }).catch(e=>{
                        this.loading = false;
                    })
                }
            })
        },
        submitPass(ruleForm){
            this.$refs[ruleForm].validate(a=>{
                if(a){
                    this.loadingPass = true;
                    let {oldPassword,newPassword} = this.passForm;
                    let data = {oldPassword,newPassword,confirmPassword:newPassword,id:this.formUser.id,version:this.formUser.version};
                    this.axios({
                        url:'/api/Admin/User/ChangePassword',
                        method:'put',
                        data,
                    }).then(res=>{
                        console.log(res.data);
                        if(res.data.code == 1){
                            this.loadingPass = false;
                            this.passForm = {};
                            this.showdig = false;
                            this.$message({
                                message:"密码修改成功",
                                type:'success',
                            })
                            Getbasic().then(res=>{
                                this.SET_ID(res.data.data.id)
                                this.SET_avatar(res.data.data.avatar)
                                this.SET_nickName(res.data.data.nickName)
                                this.SET_version(res.data.data.version)
                            })    
                        }else{
                            this.$message({
                                message:res.data.msg,
                                type:'error'
                            });
                            this.loadingPass = false;
                        }
                    }).catch(e=>{
                        this.loadingPass = false;
                    })
                }
            })
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
            this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
            this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
        },
        av_upload(file){
            let formData = new FormData();
            formData.append('file',file.file)
            this.axios({
                url:'/api/admin/user/avatarupload',
                method:'post',
                data:formData
            }).then(res => {
                this.imageUrl = res.data.data;
            })
        },
    }
}
</script>

<style scoped>
.user-list >>> .item .el-badge__content.is-fixed{
    top:14px;
    right: 15px;
    border: 1px solid #d5c9b5;
}
.ed-avatar >>> .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}
.ed-avatar >>> .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}
.ed-avatar >>> .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
}
.avatar {
    width: 178px;
    height: 178px;
    display: block;
}
.user_form>div{
    width: 90%;
}
.acc_dlg >>> .el-dialog{
    border:2px solid #3d5354;
}
.acc_dlg >>> .el-dialog__header{
    background: #3d5354;
}
.acc_dlg >>> .el-dialog__title{
    color: #fff;
    font-size: 20px;
}
.acc_dlg >>> .el-dialog__headerbtn .el-icon-close{
    color:#fff !important;
}
.acc-tab >>> .el-tabs__nav{
    width: 100%;
}
.acc-tab >>> .el-tabs__item{
    /* width:; */
    text-align: center;
    font-family: "微软雅黑";
    font-weight: 600;
}
.acc-user-value.intro{
    width: calc(100% - 92px);
    height: 50px;
    display: table;
    /* border:1px solid #eee; */
}
.acc-user-value.intro>div{
    display: table-cell;
    vertical-align: middle;
    text-align: left;
}
.acc-user-value{
    /* display: inline-block; */
    float: left;
    color: #777;
    padding-left: 12px;
    font-size: 15px;
    font-family: '微软雅黑';
    box-sizing: border-box;
}
.acc_user-label.h50{
    height: 50px;
    line-height: 50px;
}
.acc_user-label{
    /* display: inline-block; */
    float: left;
    width: 90px;
    text-align: right;
    vertical-align: middle;
    font-size: 15px;
    font-weight: 600;
}
.acc_user-info>li::after{
    content:'';
    display: table;
    clear: both;
}
.acc_user-info>li{
    padding: 10px 0;
}
</style>