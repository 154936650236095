<template>
    <div class="betterway" @click="$router.push('/bestway?routesTypes=精品路线')">
        <div class="left">
            <div class="wayImg" v-for="item in 12" :key="'img'+item"></div>
        </div>
        <div class="right">
            <div class="title">西宁10条精品旅游线路带你邂逅乡村最美风景</div>
            <div class="line-item" v-for="item in lineList" :key="item">{{item}}</div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            lineList:[
                '线路1:西纳川休闲农业乡村旅游精品线路',
                '线路2:河湟历史遗迹乡村旅游精品线路',
                '线路3:非遗历史文化乡村旅游精品线路',
                '线路4:民俗民间文化乡村旅游精品线路',
                '线路5:山水共和生态农业乡村旅游精品线路',
                '线路6:朔北乡鸾沟民俗文化乡村旅游精品线路',
                '线路7:自然生态健身乡村旅游精品线路',
                '线路8:田园风光民俗文化旅游精品线路',
                '线路9:涅源县历史文化乡村旅游精品线路',
                '线路10:茶马互市生态休闲乡村旅游精品线路',
            ]
        }
    }
}
</script>

<style scoped>
.line-item{
    font-size: 22px;
    font-weight: bold;
    position: relative;
    padding-left: 50px;
    line-height: 30px;
}

.line-item::before{
    content: '';
    display: block;
    width: 36px;
    height: 10px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
}
.line-item:nth-child(2)::before{
    background: #ff0000;
}
.line-item:nth-child(3)::before{
    background: #d9e021;
}
.line-item:nth-child(4)::before{
    background: #00ff00;
}
.line-item:nth-child(5)::before{
    background: #29abe2;
}
.line-item:nth-child(6)::before{
    background: #0000ff;
}
.line-item:nth-child(7)::before{
    background: #662d91;
}
.line-item:nth-child(8)::before{
    background: #ff7bac;
}
.line-item:nth-child(9)::before{
    background: #754c24;
}
.line-item:nth-child(10)::before{
    background: #f7931e;
}
.line-item:nth-child(11)::before{
    background: #006837;
}
.title{
    font-size: 36px;
    border-bottom: 3px solid #ccc;
    line-height: 50px;
    text-indent: 42px;
    font-weight: bold;
}
.betterway{
    height: 657px;
    display: flex;
    margin: 40px 0px;
}
.left{
    width: 700px;
    height: 100%;
    position: relative;
    background: url('../../assets/img/bg/way.png') no-repeat center center;
}
.right{
    width: calc(100% - 700px);
    height: 100%;
    /* background-image: url('../../assets/img/bg/waytext.png');
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 85% 100%; */
    padding-left: 60px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

}
.wayImg{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: absolute;
    transition: all .4s;
}
.wayImg:hover{
    transform: scale(1.5,1.7);
    /* height: 130px;
    width: 150px; */
    border-radius: 10px;
    background-size: 100% auto;
}
.wayImg:nth-child(1){
    background-image: url('../../assets/img/way/1.jpg');
    top: -50px;
    left: 190px;
}
.wayImg:nth-child(2){
    background-image: url('../../assets/img/way/2.jpg');
    top: 159px;
    left: 0px;
}
.wayImg:nth-child(3){
    background-image: url('../../assets/img/way/3.jpg');
    top: 100px;
    left: 170px;
}
.wayImg:nth-child(4){
    background-image: url('../../assets/img/way/4.jpg');
    top: 128px;
    left: 317px;
}
.wayImg:nth-child(5){
    background-image: url('../../assets/img/way/5.jpg');
    top: 148px;
    left: 628px;
}
.wayImg:nth-child(6){
    background-image: url('../../assets/img/way/6.jpg');
    top: 410px;
    left: 0px;
}
.wayImg:nth-child(7){
    background-image: url('../../assets/img/way/7.jpg');
    top: 484px;
    left: 132px;
}
.wayImg:nth-child(8){
    background-image: url('../../assets/img/way/8.jpg');
    top: 568px;
    left: 20px;
}
.wayImg:nth-child(9){
    background-image: url('../../assets/img/way/9.jpg');
    top: 575px;
    left: 243px;
}
.wayImg:nth-child(10){
    background-image: url('../../assets/img/way/10.jpg');
    top: 550px;
    left: 570px;
}
.wayImg:nth-child(11){
    background-image: url('../../assets/img/way/11.jpg');
    top: -40px;
    left: 454px;
}
.wayImg:nth-child(12){
    background-image: url('../../assets/img/way/12.jpg');
    top: 244px;
    left: 120px;
}
</style>