<template>
    <div class="dialogLogin">
        <el-dialog title="用户登录" :visible.sync="dialogVisible" width="480px" align="center" @close="dialogClose">
            <div class="login-box">
                <div class="login-form">
                    <div>
                        <div class="login-item row " :class="{err:err.userName}">
                            <div class="login-icon user"></div>
                            <el-input v-model="form.userName" placeholder="请输入手机号码" @blur="check('userName')"></el-input>
                        </div>
                        <div class="login-item row" :class="{err:err.password}">
                            <div class="login-icon password"></div>
                            <el-input v-model="form.password" type="password" placeholder="请输入密码" @blur="check('password')"></el-input>
                        </div>
                        <div class="row ">
                            <div class="login-item yzm row" :class="{err:err.code}">
                                <div class="login-icon check"></div>
                                <el-input v-model="form.code" placeholder="请输入验证码" @blur="check('code')" style="width:192px"></el-input>
                            </div>
                            <div @click="refreshCode" class="code">
                                <SIdentify :identifyCode="identifyCode" :fontSizeMin="36" :fontSizeMax="48" :contentWidth="120" :contentHeight="46"></SIdentify>
                            </div>
                        </div>  
                    </div>
                    <div class="submit" @click="submit" v-loading="loading">登 录</div>
                    <div class="signIn"><router-link to="/signIn">没有账号？去注册 ></router-link>  </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import SIdentify from '@/components/SIdentify'
import oNav from '@/components/nav'
import {login,GetUserInfo,Getbasic} from '@/api/signIn'
import { mapMutations} from 'vuex'
export default {
    components:{oNav,SIdentify},
    props:{
        showLogin:{type:Boolean,default:false,}
    },
    watch:{
        showLogin:{
            handler(a){
                this.dialogVisible = a;
            },
            immediate: true
        }
    },
    data(){
        return{
            form:{},
            identifyCodes: "1234567890abcdefghigklmnopqrstuvwxyz",
            identifyCode: "",
            err:{userName:false,password:false,code:false},
            loading:false,
            dialogVisible:true,
        }
    },
    methods:{
        ...mapMutations(['SET_TOKEN','SET_ID','SET_avatar','SET_nickName','SET_userName','SET_version']),
        dialogClose(){
            this.$emit('close')
            this.err = {userName:false,password:false,code:false}
            this.form = {}
        },
        //验证码
        randomNum(min, max) {
            return Math.floor(Math.random() * (max - min) + min);
        },
        refreshCode() {
            this.identifyCode = "";
            this.makeCode(this.identifyCodes, 4);
        },
        makeCode(o, l) {
            for (let i = 0; i < l; i++) {
                this.identifyCode += this.identifyCodes[
                    this.randomNum(0, this.identifyCodes.length)
                ];
            }
            this.reTime = new Date().getTime();
        },
        check(key){
            if(this.form[key]&&this.form[key].trim()){
                // this.err.userName = false
                this.$set(this.err,key,false)
                return true
            }else{
                this.$set(this.err,key,true)
                return false
            }
        },
        submit(){
            if(this.check('userName')&&this.check('password')&&this.check('code')){
                if(this.form.code !== this.identifyCode){
                    this.$message({
                        message:'验证码不正确',
                        type:'error'
                    })
                    this.makeCode();
                }else{
                    let {userName,password} = this.form;
                    const data = {userName,password};
                    this.loading = true
                    login(data).then(res=>{
                        console.log(res.data);
                        if(res.data.code == 1){
                            this.SET_TOKEN(res.data.data.token)
                            Getbasic().then(res=>{
                                this.SET_ID(res.data.data.id)
                                this.SET_avatar(res.data.data.avatar)
                                this.SET_nickName(res.data.data.nickName)
                                this.SET_version(res.data.data.version)
                            })
                            GetUserInfo().then(res=>{
                                this.SET_userName(res.data.data.user.userName)
                            })
                            this.loading = false
                            this.dialogVisible = false
                            // this.$router.push('/')
                        }else{
                            this.$message({
                                message:res.data.msg,
                                type:'error'
                            })
                            this.loading = false;

                        }
                    }).catch(()=>{
                        this.loading = false;
                    })
                }
            }else{
                return false;
            }
        }
    },
    mounted(){
        this.identifyCode = "";
        this.makeCode(this.identifyCodes, 4);
    }
}
</script>

<style scoped>
.dialogLogin >>>.el-dialog{
    border-radius: 20px;
}
.login-item.err{
    background:  #f1c2c2;
}
.code{
    line-height: 48px;
    margin-top: 30px;
    cursor: pointer;
}
.submit:hover{
    background: #4997f7;
    cursor: pointer;
}
.signIn a:hover{
    color: #539cf5;
    text-decoration: underline;
    cursor: pointer;
}
.signIn a{
    color: #1a80ff;
}
.signIn {
    text-align: right;
    font-size: 18px;
    line-height: 50px;
    padding-right: 50px;
}
.submit{
    line-height: 48px;
    border-radius: 34px;
    font-size: 24px;
    color: #fff;
    background: #1a80ff;
    text-align: center;
    margin-top: 30px;
}
.login-item >>> .el-input{
    width: 325px;
    font-size: 20px;
    
}
.login-item.yzm >>> .el-input{
    width: 220px;
}
.login-item.yzm {
    width: 260px;
}
.login-item >>> .el-input__inner::placeholder{
    color: #4d98f3;
}
.login-item >>> .el-input__inner{
    color: #1a80ff;
    background-color:transparent;
    border: 0;
}
.login-icon.user{
    background-image: url(../../public/img/login/icon-user.png);
}
.login-icon.password{
    background-image: url(../../public/img/login/icon-password.png);
}
.login-icon.check{
    background-image: url(../../public/img/login/icon-check.png);
    background-size: 52%;
}
.login-icon{
    margin-left: 20px;
    width: 48px;
    height: 48px;
    background-size: 50%;
    background-repeat: no-repeat;
    background-position: center center;
}
.login-item{
    background: #c8e1ff;
    line-height: 48px;
    border-radius: 34px;
    margin-top: 30px;
}
.login-form{
    padding: 5px 30px;
}
.tab{
    display: flex;
}
.tab-item.act{
    color: #1a80ff;
    border-bottom: 1px solid #1a80ff;
}
.tab-item{
    /* width: 50%; */
    width: 100%;
    text-align: center;
    line-height: 80px;
    font-size: 24px;
    color: #999;
    border-bottom: 1px solid #ccc;
}
.login-box{
    width: 440px;
    background: #fff;
    padding-bottom: 20px;
}
.main{
    height: calc(100vh - 66px);
    background: url(../../public/img/login/logo-bg.png) no-repeat center center;
    position: relative;
}
</style>