<template>
    <div class="chuang-ke">
        <div class="left">
            <div>
                <div style="font-size: 20px;line-height: 30px;">创客空间</div>
                <div style="font-size:36px;line-height: 60px;">创客故事</div>
                <div style="font-size:16px;line-height: 24px;color: #898989;">用自己的行动为三乡工程做出贡献，为家乡拓展了一条条致富的新路子”</div>
                <div>
                    <el-button type="text" @click="$router.push('/List?infoType=259036554174533&type=创客之星')">查看更多<i class="el-icon-arrow-right"></i></el-button>
                </div>
            </div>
            
        </div>
        <div class="right">
            <div class="rl">
                <div class="rl-1f">
                    <div class="imgbox" @click="goDetail(p1.id)">
                        <el-image fit="cover" style="width:100%;height:100%"  :src="p1.photoUrls"></el-image>
                        <div class="title">{{p1.title}}</div>
                    </div>
                    <div class="imgbox" @click="goDetail(p2.id)">
                        <el-image fit="cover" style="width:100%;height:100%"  :src="p2.photoUrls"></el-image>
                        <div class="title">{{p2.title}}</div>
                    </div>
                </div>
                <div class="rl-2f">
                    <div class="imgbox" @click="goDetail(p3.id)">
                        <el-image fit="cover" style="width:100%;height:100%"  :src="p3.photoUrls"></el-image>
                        <div class="title">{{p3.title}}</div>
                    </div>
                </div>
                <div class="fl-3f">
                    <div class="imgbox" @click="goDetail(p4.id)">
                        <el-image fit="cover" style="width:100%;height:100%"  :src="p4.photoUrls"></el-image>
                        <div class="title">{{p4.title}}</div>
                    </div>
                    <div class="imgbox" @click="goDetail(p5.id)">
                        <el-image fit="cover" style="width:100%;height:100%"  :src="p5.photoUrls"></el-image>
                        <div class="title">{{p5.title}}</div>
                    </div>
                </div>
            </div>
            <div class="rr">
                <div class="rr-1f">
                    <div class="rr-1f-l">
                        <div class="r1">
                            <div class="imgbox" @click="goDetail(p6.id)">
                                <el-image fit="cover" style="width:100%;height:100%"  :src="p6.photoUrls"></el-image>
                                <div class="title">{{p6.title}}</div>
                            </div>
                        </div>
                        <div class="r2">
                            <div class="imgbox" @click="goDetail(p7.id)">
                                <el-image fit="cover" style="width:100%;height:100%"  :src="p7.photoUrls"></el-image>
                                <div class="title">{{p7.title}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="rr-1f-r">
                        <div class="r3">
                            <div class="imgbox" @click="goDetail(p8.id)">
                                <el-image fit="cover" style="width:100%;height:100%"  :src="p8.photoUrls"></el-image>
                                <div class="title">{{p8.title}}</div>
                            </div>
                        </div>
                        <div class="r4">
                            <div class="imgbox" @click="goDetail(p9.id)">
                                <el-image fit="cover" style="width:100%;height:100%"  :src="p9.photoUrls"></el-image>
                                <div class="title">{{p9.title}}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="rr-2f">
                    <div>
                        <div class="imgbox" @click="goDetail(p10.id)">
                            <el-image fit="cover" style="width:100%;height:100%"  :src="p10.photoUrls"></el-image>
                            <div class="title">{{p10.title}}</div>
                        </div>
                    </div>
                    <div>
                        <div class="imgbox" @click="goDetail(p11.id)">
                            <el-image fit="cover" style="width:100%;height:100%"  :src="p11.photoUrls"></el-image>
                            <div class="title">{{p11.title}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { GetInformationPage } from '@/api/home'
export default {
    data(){
        return{
            p1:{photoUrls:require('../../../public/img/home/c1.png'),title:'演示数据'},
            p2:{photoUrls:require('../../../public/img/home/c2.png'),title:'演示数据'},
            p3:{photoUrls:require('../../../public/img/home/c3.png'),title:'演示数据'},
            p4:{photoUrls:require('../../../public/img/home/c4.png'),title:'演示数据'},
            p5:{photoUrls:require('../../../public/img/home/c5.png'),title:'演示数据'},
            p6:{photoUrls:require('../../../public/img/home/c6.png'),title:'演示数据'},
            p7:{photoUrls:require('../../../public/img/home/c7.png'),title:'演示数据'},
            p8:{photoUrls:require('../../../public/img/home/c8.png'),title:'演示数据'},
            p9:{photoUrls:require('../../../public/img/home/c9.png'),title:'演示数据'},
            p10:{photoUrls:require('../../../public/img/home/c10.png'),title:'演示数据'},
            p11:{photoUrls:require('../../../public/img/home/c11.png'),title:'演示数据'},
            pageData:[],
            params:{
                currentPage: 1,
                filter: {infoType: 259036554174533},
                pageSize: 11,
            }
        }
    },
    methods:{
        getPageData(){
            GetInformationPage(this.params).then(res=>{
                if (res.data?.success == true) {
                    this.pageList = res.data.data.list;
                    this.pageList.forEach((item,k)=>{
                        this[`p${k+1}`] = item
                    })
                }
            })

        },
        goDetail(id){
            
            id&&this.$router.push({path:'/detail',query:{id,type:'创客之星',oParams:JSON.stringify(this.params)}})
        }

    },
    mounted(){
        this.getPageData();
    }
}
</script>

<style scoped>
.chuang-ke{
    display: flex;
    justify-content: space-between;
}
.left{
    width: 35%;
    height: 500px;
    /* background: #eee; */
    padding: 80px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: url(../../assets/img/hot-icon/chuangke--bg.png) no-repeat center center;
    background-size: 100% 100%;
}
.right{
    width: 63%;
    display: flex;
    justify-content: space-between;
    /* overflow: hidden; */
}
.rl{
    width: 320px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.rl-1f,.fl-3f{
    display: flex;
    justify-content: space-between;
    height: 120px;
}
.rl-2f{
    height: 240px;
}
.rl-1f>div{
    width: calc(50% - 4px);
}
.fl-3f>.imgbox:nth-child(1){
    width: calc(38% - 4px);
}
.fl-3f>.imgbox:nth-child(2){
    width: calc(62% - 4px);
}
.rr{
    width: calc(100% - 330px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.imgbox{
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    position: relative;
    transition: all .8s;
    cursor: pointer;
}
.title{
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    text-align: center;
    justify-content: center;
    flex-direction: column;
    background: rgba(4, 113, 236, 0.6);
    font-size: 18px;
    color: #fff;
    line-height: 24px;
    opacity: 0;
}
.imgbox:hover .title{
    opacity: 1;
}
.imgbox:hover{
    transform: scale(1.1);

}
.rr-1f{
    height: 300px;
    display: flex;
    justify-content: space-between;
}
.rr-1f-l{
    width: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.r1{
    height: 130px;
}
.r2{
    height: 162px;
}
.rr-1f-r{
    width: calc(100% - 158px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.r3{
    height: 170px;
}
.r4{
    height: 122px;
}
.rr-2f{
    display: flex;
    justify-content: space-between;
}
.rr-2f>div{
    width: calc(50% - 4px);
    height: 192px;
}
</style>