<template>
    <nav class="clearfix nav">
        <div class="title fl">
            <img src="../assets/img/LOGO.png" style="height:62px" alt="">
        </div>
        <ul class="clearfix fl">
            <li class="fl nav-item" :class="{active:!showAct}"><router-link to="/">首页</router-link> </li>
            <li class="fl nav-item ys">
                <router-link to="/xiangninghui" class="ysz">
                    乡宁<span class="hui"><svg-icon icon-name="w_hui" class="icon-class"></svg-icon></span>
                </router-link> 
            </li>
            <li class="fl nav-item ys">
                <router-link to="/talents" class="ysz">
                乡宁<span><svg-icon icon-name="w_qing" class="icon-class"></svg-icon></span>
                </router-link> 
            </li>
            <li class="fl nav-item ys">
                <router-link to="/xiangningwang" class="ysz">
                    乡宁<span class="wang"><svg-icon icon-name="w_wang" class="icon-class"></svg-icon></span>
                </router-link> 
            </li>
            <li class="fl nav-item"><router-link to="/resource">资源集市</router-link> </li>
            <li class="fl nav-item"><router-link to="/sanxiangxueyuan">三农学院</router-link> </li>
            <li class="fl nav-item"><router-link to="/sanxiangguangbo">三农广播</router-link> </li>
            <li class="fl nav-item"><router-link to="/antipoverty">脱贫攻坚</router-link> </li>
        </ul>
    </nav>
</template>
<script>
export default {
    props:{
        showAct:{type:Boolean,default:false}
    }
}
</script>
<style scoped>
.nav-item .icon-class{
    width: 52px;
    height: 60px;
    fill: currentColor;
    color: red;
    vertical-align: middle;
    transform: translate(-2px,-6px);
}
.nav-item .wang .icon-class{
    transform: translate(-4px,-6px);
}
.nav-item .hui .icon-class{
    transform: translate(-6px,-6px);
}
.nav-item:hover a:not(.ysz){
    background: #4c93d6;
    color:#fff;
}
.nav-item.ys:hover{
    transform: scale(1.05);
}
.nav-item.active a{
    background: #367bff;
    color:#fff;
}
.nav-item a{
    font-size: 24px;
    padding: 21px 16px;
    color:#151515;
}
.nav-item a.ysz{
    color: #367bff;
    vertical-align: top;
}
.nav-item{
    height: 66px;
    margin-right: 10px;
    line-height: 66px;
}
.title{
    width: 38%;
    height: 66px;
    padding-top: 2px;
    text-align: center;
}
.title img{
    height: 32px;
}
.title .text{
    font-size: 40px;
    color: #0468be;
    font-weight: 600;
}
.nav{
    background: #fff;
}
</style>