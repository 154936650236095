import axios from '@/utils/axios';
export function GetList(data){
    /**
     * 资讯类 列表获取 
     **/
    return axios({
        method:'post',
        url: "/api/xnsxinfo/information/getpageforweb",
        data,
    })
}
export function GetDetail(id){
    /**
     * 资讯类 详情
     **/
    return axios({
        method:'get',
        url: "/api/xnsxinfo/information/Get",
        params:{id},
    })
}
export function GetStatVillage(){
    /**
     * 美丽乡村区划占比 
     **/
     return axios({
        method: 'post',
        url: "/api/XnsxInfo/Statistics/StatVillage",
    })
}
export function GetScenerySpot(){
    /**
     * 景点浏览排行榜
     **/
     return axios({
        method: 'post',
        url: "/api/XnsxInfo/Statistics/StatScenerySpot",
    })
}
export function GetStatProject(){
    /**
     * 乡宁荟交易数据项目统计
     **/
     return axios({
        method: 'post',
        url: "/api/XnsxInfo/Statistics/StatProject",
    })
}
export function GetXnRecruit(){
    /**
     * 乡宁直聘趋势统计
     **/
     return axios({
        method: 'post',
        url: "/api/XnsxInfo/Statistics/StatXnRecruit",
    })
}
export function GetStatEnterprise(){
    /**
     * 兴乡企业区划占比
     **/
     return axios({
        method: 'post',
        url: "/api/XnsxInfo/Statistics/StatEnterprise",
    })
}
export function GetStatRentDone(){
    /**
     * 资源集市交易趋势统计
     **/
     return axios({
        method: 'post',
        url: "/api/XnsxInfo/Statistics/StatRentDone",
    })
}
export function SuggestionAdd(data){
    /**
     * 建议信箱提交
     **/
     return axios({
        method: 'post',
        url: "/api/Resource/Suggestion/Add",
        data
    })
}