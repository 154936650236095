import Axios from 'axios'
import {
  Message
} from 'element-ui'
import store from '../store'
import vueMain from '@/main.js'
import router from '../router'

// 创建axios实例
const service = Axios.create({
  baseURL:process.env.VUE_APP_BASE_API, // api 的 base_url
  // baseURL: 'http://192.168.1.107:8000', // api 的 base_url
  timeout: 60000 // 请求超时时间
})

// request拦截器state.user.token
service.interceptors.request.use(
  config => {
    if (store.state.token) {
      config.headers['Authorization'] = 'Bearer ' + store.state.token // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    return config
  },
  error => {
    // Do something with request error
    console.log(error) // for debug
    Promise.reject(error)
  }
)
// console.log('拦截器'+error)
// response 拦截器
let msg = null;
service.interceptors.response.use(
  response => {
    return response
  },
  error => {
    if(error.message.includes('Network')){   // 判断请求异常信息中是否含有超时timeout字符串    
      alert("连接超时");
      // Message({
      //   message:'连接超时'
      // })
      return Promise.reject(error);          // reject这个错误信息
    }
    switch (error.response.status) {
      case 400:
        error.message = error.response.data
        break
      case 401:
        error.message = '未登录或登录凭据已失效，请重新登录'
        console.log(store)
        store.commit('SET_TOKEN',null)
        // store.dispatch('serverLoginOut').then(() => {
        //   setTimeout(() => {
        //     vueMain.$bus.$emit('noLogin',true);
        //   }, 2200);
          
        // })
        break
      case 404:
        error.message = '接口发生变更，请刷新后重试'
        break
      case 500:
        error.message = '服务器发生错误，请联系管理员'
        break
      default:
        error.message = '未知错误'
    }
    
    if(msg) msg.close();
    msg = Message({
      message: error.message,
      type: 'error',
      duration: 2000
    })
    return Promise.reject(error)
  }
)
export default service