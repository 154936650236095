<template>
  <div class="house clearfix">
    <div class="house-header clearfix" >
      <div class="h-label">
        <div :class="{ act: type == '农房' }" @click="changeF('农房')">闲置农房</div>
        <div :class="{ act: type == '土地' }" @click="changeF('土地')">土地</div>
      </div>
      <div class="menubox">
        <ul class="h-region clearfix">
          <li
            class="fl"
            v-for="(p, k) in region"
            :key="k + 'region'"
            :class="{act : params.filter.regionCode == p.code}"
            @click="
              params.filter.regionCode = p.code;
              getPageList();
            "
          >
            <span>{{ p.name }}</span>
          </li>
        </ul>
        <!-- <div class="more">
            <router-link :to="`/resource?type=${type}`">
                <span>更多<i class="iconfont icon-htmal5icon45"></i></span>
            </router-link>
        </div> -->
      </div>
      
    </div>
    <div class="fl houseL">
      <div class="house-main">
        <div class="house-view" v-if="pageData&&pageData.length>0">
          <div class="h-1f">
            <div class="h-1f-l p1" @click="goDetail(p1.id)">
              <el-carousel height="340px" arrow="never">
                <el-carousel-item v-for="(item,k) in p1.photoUrls" :key="k+'house'" >
                  <div class="imgbox">
                    <el-image fit="cover" style="width:100%;height:100%"  :src="item"></el-image>
                  </div>
                </el-carousel-item>
              </el-carousel>
              
              <!-- <div class="h-name"></div> -->
            </div>
            <div class="h-1f-r">
              <div class="p2" @click="goDetail(p2.id)">
                <div class="imgbox">
                  <el-image fit="cover" style="width:100%;height:100%"  :src="p2.photoUrls[0]"></el-image>
                </div>
                <div class="h-name"><i class="el-icon-s-home"></i>{{p2.title}}</div>
              </div>
              <div class="p3" @click="goDetail(p3.id)">
                <div class="imgbox">
                  <el-image fit="cover" style="width:100%;height:100%"  :src="p3.photoUrls[0]"></el-image>
                </div>
                <div class="h-name"><i class="el-icon-s-home"></i>{{p3.title}}</div>
              </div>
            </div>
          </div>
          <div class="h-2f">
            <div class="p4" @click="goDetail(p4.id)">
              <div class="imgbox">
                <el-image fit="cover" style="width:100%;height:100%"  :src="p4.photoUrls[0]"></el-image>
              </div>
              <div class="h-name"><i class="el-icon-s-home"></i>{{p4.title}}</div>
            </div>
            <div class="p5" @click="goDetail(p5.id)">
              <div class="imgbox">
                  <el-image fit="cover" style="width:100%;height:100%"  :src="p5.photoUrls[0]"></el-image>
                </div>
                <div class="h-name"><i class="el-icon-s-home"></i>{{p5.title}}</div>
            </div>
            <div class="p6" @click="goDetail(p6.id)">
              <div class="imgbox">
                  <el-image fit="cover" style="width:100%;height:100%"  :src="p6.photoUrls[0]"></el-image>
                </div>
                <div class="h-name"><i class="el-icon-s-home"></i>{{p6.title}}</div>
            </div>
          </div>
        </div>
        <!-- <el-row :gutter="30" v-if="pageData&&pageData.length>0">
          <el-col
            :span="8"
            v-for="(item, index) in pageData"
            :key="index + 'houseItem'"
            class="mb-20"
          >
            <a href="javascript:;">
              <div class="house-item">
                  <router-link :to="`/propertydetail?id=${item.id}&type=1`">
                <el-image
                  style="width: 100%; height: 100%"
                  :src="item.photoUrls && item.photoUrls.split(',')[0]"
                  fit="cover"
                >
                </el-image>
                  </router-link>
                <div class="house-item-title">
                  {{ item.title }}
                </div>
              </div>
            </a>
          </el-col>
        </el-row> -->
        <div class="empty" v-else>
          <el-empty :image-size="300" description="暂无相关数据"></el-empty>
        </div>
      </div>
    </div>
    <div class="fr house-query">
      <div v-if="type == '农房'">
        <div>
          <p class="query-title">租金</p>
          <ul class="query-list">
            <li
              @click="
                params.filter.priceRange = '0-1000';
                params.filter.resource = '农房';
                getPageList();
              "
              :class="{act:params.filter.priceRange == '0-1000'}"
            >
              1千元以下/月
            </li>
            <li
              @click="
                params.filter.priceRange = '1000-2000';
                params.filter.resource = '农房';
                getPageList();
              "
              :class="{act:params.filter.priceRange == '1000-2000'}"

            >
              1~2千元/月
            </li>
            <li
              @click="
                params.filter.priceRange = '2000-3000';
                params.filter.resource = '农房';
                getPageList();
              "
              :class="{act:params.filter.priceRange == '2000-3000'}"
            >
              2~3千元/月
            </li>
            <li
              @click="
                params.filter.priceRange = '3000-1000000000';
                params.filter.resource = '农房';
                getPageList();
              "
              :class="{act:params.filter.priceRange == '3000-1000000000'}"
            >
              3千元以上/月
            </li>
          </ul>
        </div>
        <div>
          <p class="query-title">面积</p>
          <ul class="query-list">
            <li
              @click="
                params.filter.floorAreaRange = '0-100';
                params.filter.resource = '农房';
                getPageList();
              "
              :class="{act:params.filter.floorAreaRange == '0-100'}"
            >
              100m²以下
            </li>
            <li
              @click="
                params.filter.floorAreaRange = '100-300';
                params.filter.resource = '农房';
                getPageList();
              "
              :class="{act:params.filter.floorAreaRange == '100-300'}"
            >
              100~300m²
            </li>
            <li
              @click="
                params.filter.floorAreaRange = '300-500';
                params.filter.resource = '农房';
                getPageList();
              "
              :class="{act:params.filter.floorAreaRange == '300-500'}"
            >
              300~500m²
            </li>
            <li
              @click="
                params.filter.floorAreaRange = '500-10000000';
                params.filter.resource = '农房';
                getPageList();
              "
              :class="{act:params.filter.floorAreaRange == '500-10000000'}"
            >
              500m²以上
            </li>
          </ul>
        </div>
        <div>
          <p class="query-title">特色</p>
          <ul>
            <li
              v-for="item in featureData"
              :key="item.features"
              class="feature-item fl"
              :class="{ act: params.filter.features == item.features }"
              @click="
                  params.filter.features = item.features;
                  getPageList();
                "
            >
              <div
                class="iconPic iconfont"
                :class="[
                  item.icon,
                ]"
              ></div>
              <div class="iconVal">{{ item.features }}</div>
            </li>
          </ul>
        </div>
      </div>
      <div v-else>
        <div>
          <p class="query-title">面积</p>
          <ul class="query-list">
            <li
              @click="
                params.filter.floorAreaRange = '0-10';
                params.filter.resource = '土地';
                getPageList();
              "
              :class="{act:params.filter.floorAreaRange == '0-10'}"
            >
              10亩以下
            </li>
            <li
              @click="
                params.filter.floorAreaRange = '10-100';
                params.filter.resource = '土地';
                getPageList();
              "
              :class="{act:params.filter.floorAreaRange == '10-100'}"
            >
              10～100亩
            </li>
            <li
              @click="
                params.filter.floorAreaRange = '100-1000';
                params.filter.resource = '土地';
                getPageList();
              "
              :class="{act:params.filter.floorAreaRange == '100-1000'}"
            >
              10～1000亩
            </li>
            <li
              @click="
                params.filter.floorAreaRange = '1000-10000000';
                params.filter.resource = '土地';
                getPageList();
              "
              :class="{act:params.filter.floorAreaRange == '1000-10000000'}"

            >
              1000亩以上
            </li>
          </ul>
        </div>
        <div>
          <p class="query-title">年限</p>
          <ul class="query-list">
            <li
              @click="
                params.filter.buildYearRange = '0-10';
                params.filter.resource = '土地';
                getPageList();
              "
              :class="{act:params.filter.buildYearRange == '0-10'}"
            >
              10年及以下
            </li>
            <li
              @click="
                params.filter.buildYearRange = '10-20';
                params.filter.resource = '土地';
                getPageList();
              "
              :class="{act:params.filter.buildYearRange == '10-20'}"
            >
              10～20年
            </li>
            <li
              @click="
                params.filter.buildYearRange = '20-50';
                params.filter.resource = '土地';
                getPageList();
              "
              :class="{act:params.filter.buildYearRange == '20-50'}"
            >
              20～50年
            </li>
            <li
              @click="
                params.filter.buildYearRange = '50-1000000';
                params.filter.resource = '土地';
                getPageList();
              "
              :class="{act:params.filter.buildYearRange == '50-1000000'}"
            >
              50年以上
            </li>
          </ul>
        </div>
        <div>
          <p class="query-title">用途</p>
          <ul class="clearfix">
            <li
              v-for="item in tudiyongtu"
              :key="item.icon"
              class="feature-item fl"
              :class="{ act: params.filter.suitableUses == item.suitableUses }"
              @click="
                  params.filter.suitableUses = item.suitableUses;
                  getPageList();
                "
            >
              <div
                class="iconPic iconfont"
                :class="[
                  item.icon,
                ]"
                
              ></div>
              <div class="iconVal">{{ item.suitableUses }}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { GetRentInfos } from "@/api/ziyuanjishi.js";
export default {
  data() {
    return {
      type: '农房',
      pageData: [],
      region: [
        { name: "全部", code: 0 },
        { name: "大通县", code: 630121 },
        { name: "湟源县", code: 630123 },
        { name: "湟中区", code: 630122 },
        { name: "城东区", code: 630102 },
        { name: "城中区", code: 630103 },
        { name: "城西区", code: 630104 },
        { name: "城北区", code: 630105 },
      ],
      tudiyongtu: [
        { icon: "icon-minsu", suitableUses: "民宿" },
        { icon: "icon-nongjiale", suitableUses: "农家乐" },
        { icon: "icon-kangyang", suitableUses: "康养" },
        { icon: "icon-gongzuoshi", suitableUses: "工作室" },
        { icon: "icon-cangchu", suitableUses: "仓储" },
        // { icon: "icon-shouyetudishangfuyongdi", suitableUses: "商服用地" },
        // { icon: "icon-shouyetudigonggongyongdi", suitableUses: "公共用地" },
        // { icon: "icon-shouyetudiqitayongdi", suitableUses: "其它用地" },
      ],
      houseData: [],
      featureData: [
        { icon: "icon-a-ziyuan38", features: "山景" },
        { icon: "icon-a-ziyuan32", features: "高性价比" },
        { icon: "icon-a-ziyuan41", features: "独立院落" },
        { icon: "icon-a-ziyuan30", features: "近高速" },
        { icon: "icon-a-ziyuan34", features: "可垂钓" },
        { icon: "icon-a-ziyuan35", features: "拎包入住" },
        { icon: "icon-a-ziyuan36", features: "养宠物" },
        { icon: "icon-a-ziyuan37", features: "可种菜" },

        { icon: "icon-a-ziyuan33", features: "景区旁" },
        // { icon: "icon-qianqian", val: "水景" },
        { icon: "icon-a-ziyuan39", features: "公交直达" },
        { icon: "icon-a-ziyuan40", features: "公路旁" },
        { icon: "icon-a-ziyuan31", features: "独门独栋" },
      ],
      params: {
        currentPage: 1,
        filter: {
          resource:'农房',regionCode:0,
        },
        pageSize: 6,
      },
      p1:{photoUrls:[require('../../../public/img/home/h1.png')],title:'农房（演示数据）'},
      p2:{photoUrls:[require('../../../public/img/home/h2.png')],title:'农房（演示数据）'},
      p3:{photoUrls:[require('../../../public/img/home/h3.png')],title:'农房（演示数据）'},
      p4:{photoUrls:[require('../../../public/img/home/h4.png')],title:'农房（演示数据）'},
      p5:{photoUrls:[require('../../../public/img/home/h5.png')],title:'农房（演示数据）'},
      p6:{photoUrls:[require('../../../public/img/home/h6.png')],title:'农房（演示数据）'}
    };
  },
  watch:{
    // type(a){
    //   this.params.filter = {}
    //   this.params.filter.resource = a;
    //   this.getPageList();
    // }
  },
  methods: {
    goDetail(id){
      id&&this.$router.push(`/propertydetail?id=${id}&type=1`)
    },
    getPageList() {
      // if(this.params.filter.regionCode == 0){
      //   this.params = 
      // }
      GetRentInfos(this.params).then((res) => {
        if (res.data?.success == true) {
          this.pageData = res.data.data.list;
          // this.pageData.forEach((item,index)=>{
          //   item.photoUrls = item.photoUrls.split(',') 
          //   this[`p${index+1}`] = item;
          // })
          for(let k = 0;k<6;k++){
            if(this.pageData[k]){
              this.pageData[k].photoUrls = this.pageData[k].photoUrls.split(',');
              this[`p${k+1}`] = this.pageData[k];
            }else{
              this[`p${k+1}`] = {photoUrls:[require(`../../../public/img/home/h${k+1}.png`)],title:'农房（演示数据）'}
            }
          }
        }
      });
        // this.params.filter.priceRange = "";
        // this.params.filter.floorAreaRange = "";
        // this.params.filter.buildYearRange = "";
        // this.params.filter.regionCode = 0;
        // this.params.filter.featureData="";
        // this.params.filter.tudiyongtu="";
      
    },
    changeF(type){
      this.params.filter = {regionCode:0};
      this.type = type;
      this.params.filter.resource = type;
      this.getPageList()
    }
  },
  mounted() {
    this.getPageList();
  },
};
</script>
<style scoped>
.house-view{
  height: 640px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.h-2f{
  display: flex;
  justify-content: space-between;
  height: 284px;
}
.p4,.p5,.p6{
  width: calc(33% - 8px);
  position: relative;
}
.p1,.p2,.p3{
  position: relative;
}
.h-name{
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  line-height: 30px;
  padding-left:10px ;
}
.h-1f{
  display: flex;
  justify-content: space-between;
  height: 340px;
}
.h-1f-l{
  width: 66%;
  height: 100%;
}
.h-1f-l >>> .el-carousel__button{
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.h-1f-r{
  width: calc(33% - 8px);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.p2,.p3{
  height: 162px;
}
.imgbox{
  width: 100%;
  height: 100%;
}
.menubox{
  display: flex;
  justify-content: space-between;
}
.empty{
  height: 500px;
}
.mb-20 {
  margin-bottom: 20px;
}
.iconVal {
  /* height: 20px; */
  line-height: 24px;
  font-size: 18px;
}
.iconPic.iconfont {
  width: 50px;
  height: 50px;
  font-size: 26px;
  line-height: 50px;
  margin: 0 auto;
  color: #0069c9;
}
.feature-item:hover .iconPic.iconfont,.feature-item.act .iconPic.iconfont {
  color: #229ee6;
}
.feature-item:hover .iconVal,.feature-item.act .iconVal {
  color: #229ee6;
}
.feature-item {
  margin: 10px 0;
  width: 80px;
  text-align: center;
  cursor: pointer;
}
.query-list li:hover,.query-list li.act{
  background-color: #def4ff;
  border: 1px solid #75d5f5;
  cursor: pointer;
}
.query-list li {
  font-family: "黑体";
  font-size: 16px;
  padding: 6px 20px;
  background-color: #f1f5f9;
  border: 1px solid #f1f5f9;
  /* color: #fff; */
  margin-top: 10px;
  min-width: 128px;
  box-sizing: border-box;
}
.query-list {
  padding: 0 50px;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.query-title {
  font-size: 20px;
  color: #0069c9;
  padding-left: 10px;
  font-weight: 600;
  height: 50px;
  line-height: 50px;
}
.house-query {
  padding: 40px 10px 10px 20px;
  width: 400px;
  box-sizing: border-box;
}
.mt-10 {
  margin-top: 10px;
}
.house-item-title {
  position: absolute;
  bottom: 0;
  height: 40px;
  color: #fff;
  line-height: 40px;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  font-size: 18px;
  padding-left: 12px;
}
.house-item {
  height: 300px;
  position: relative;
  overflow: hidden;
}
.house-main {
  height: 620px;
  background: #fff;
}
.house {
  padding: 0 5px;
}
.more {
  font-size: 18px;
  cursor: pointer;
  vertical-align: bottom;
  padding-top: 20px;
}
.more a{
  color: #666;
}
.more:hover span {
  color: #229ee6;
  cursor: pointer;
}
.h-region li:hover,.h-region li.act{
  background: #3370ff;
  color: #fff;
  cursor: pointer;
}
.h-region li {
  padding: 0 12px;
  background: #e1eaff;
  margin-right: 10px;

}
.h-region {
  margin-top: 20px;
  font-size: 18px;
  /* width: calc(100% - 200px); */
}
.h-label .act {
  font-size: 24px;
  color: #3370ff;
  border-bottom: 2px solid #3370ff;
}
.h-label > div {
  cursor: pointer;
  width: 50%;
  text-align: center;
  color: #666666;
  border-bottom: 1px solid #999;
}
.h-label > div:first-child {
  margin-left: 0;
}
.h-label {
  font-size: 24px;
  display: flex;
}
.house-header {
  line-height: 40px;
}
.houseL {
  width: calc(100% - 400px);
  padding-top: 12px;
}
</style>