<template>
    <div class="enterprise">
        <div>
            <el-carousel :interval="5000" type="card" height="360px" arrow="never" indicator-position="outside">
                <el-carousel-item v-for="(item, index) in pageData" :key="index + 'cal'">
                    <div class="card-box">
                        <div class="imgbox">
                            <el-image fit="cover" style="width:100%;height:100%" @click="$router.push(`/enterprisedetail?id=${item.id}&oParams=${JSON.stringify(params)}`)"  :src="item.photoUrls[0]"></el-image>
                        </div>
                        <div class="infos">
                            <div class="title ellipsis" @click="$router.push(`/enterprisedetail?id=${item.id}&oParams=${JSON.stringify(params)}`)">
                                <i></i>
                                <span>{{item.name}}</span>
                            </div>
                            <div class="address">
                                <i class="el-icon-location"></i>
                                {{item.address}}
                            </div>
                        </div>
                    </div>
                </el-carousel-item>
              </el-carousel>
        </div>
    </div>
</template>

<script>
import { GetEnterpriseInfos } from "@/api/xiangningwang";
export default {
    data(){
        return{
            params: {
                currentPage: 1,
                pageSize: 6,
                filter: {
                    name: "",
                    enterpriseType: "",
                    regionCode: "",
                },
            },
            pageData:[]
        }
    },
    methods: {
        getPageList() {
            GetEnterpriseInfos(this.params).then((res) => {
                const data = res.data.data.list;
                data.forEach((d) => {
                    d.photoUrls = d.photoUrls.split(",");
                });
                this.pageData = data;
            });
        },
    },
    mounted(){
        this.getPageList()
    }
}
</script>

<style scoped>
.card-box{
    /* width: 70%; */
    width: 640px;
    /* background: lightcyan; */
    height: 100%;
}
.imgbox{
    width: 480px;
    height: 280px;
    margin: 0 auto;
}
.enterprise >>> .el-carousel__mask{
    background: transparent;
}
.enterprise >>> .el-carousel__button{
    width: 10px;
    height: 10px;
    border-radius: 50%;
}
.infos{
    width: 320px;
    margin: 0 auto;
    text-align: center;
    background: #fff;
    line-height: 28px;
}
.infos .title span{
    font-size: 18px;
    color: #3488ea;
    line-height: 26px;
}
.infos .title span:hover{
    color: #509af0;
}
.infos .title i{
    vertical-align: top;
    padding: 13px 18px;
    /* width: ; */
    display: inline-block;
    background: url(../../assets/img/hot-icon/enterprise.png) no-repeat center center;
}
.infos .address{
    font-size: 14px;
    color: #666;
}
</style>