<template>
  <div class="scenery">
    <el-row :gutter="25">
      <el-col :span="16">
        <!-- <div class="s-cal">
          <div style="height: 400px">
            <router-link :to="`/scenerySpotdetail?id=${pageData.id}`">
              <el-image
                style="width: 100%; height: 100%"
                :src="actcal"
                fit="cover"
              ></el-image>
            </router-link>
          </div>
          <div class="s-cal-b">
            <div class="col-box">
              <el-carousel
                :interval="5000"
                type="card"
                height="160px"
                arrow="always"
                indicator-position="none"
                @change="calChange"
              >
                <el-carousel-item
                  v-for="(item, index) in carouselData"
                  :key="index + 'cal'"
                  >
                  <router-link :to="`/scenerySpotdetail?id=${pageData.id}`">
                    <img :src="item" alt="" width="100%" />
                  </router-link>
                </el-carousel-item>
              </el-carousel>
            </div>
          </div>
        </div> -->
        <div style="height:520px">
          <video class="videotag"  poster="../../assets/img/hot-icon/tsjd.png" :src="video4" controls width="100%" height="100%"></video>
        </div>
      </el-col>
      <el-col :span="8" class="rright">
        <div class="banner2-title clearfix">
          <div class="fl banner2-title-text">地区景点</div>
          <div class="fr more">
            <router-link to="/scenerySpotList">
              <span>更多<i class="iconfont icon-htmal5icon45"></i></span>
            </router-link>
          </div>
        </div>
        <div class="scenlist">
          <ul>
            <li
              v-for="item in scenList"
              :key="item.id + 'scen'"
              class="scen-item ellipsis"
              :class="{ isactive: item.id === pageData.id }"
            >
              <!-- <a :href="`/scenerySpotdetail?id=${item.id}`" @click="scneActice = item.id"> {{ item.name }}</a> -->
              <a
                @click="
                  scneActice = item.id;
                  setcurrentPage(item.id, item.introduction, item.photoUrls);
                "
              >
                {{ item.name }}</a
              >
            </li>
            <!-- <li class="scen-item fl">
              <a :href="`/scenerySpotList`"> 其他</a>
            </li> -->
          </ul>
        </div>
        <div class="info-box clearfix">
          <div class="info_pic">
            <router-link :to="`/scenerySpotdetail?id=${currentid}`">
              <el-image
                style="width: 100%; height: 100%"
                :src="currentphotoUrls"
                fit="cover"
              ></el-image>
            </router-link>
          </div>
          <div class="info-text ">
            {{ currentintroduction}}
          </div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { GetScenerySpotPage } from "@/api/home.js";
export default {
  props:{
    video4:String
  },
  watch:{
    video4(a){
      this.video4 = a;
    }
  },
  data() {
    return {
      actcal: "",
      carouselData: [""],
      pageData: {},
      currentphotoUrls: "",
      currentid: "",
      currentintroduction: "",
      params: {
        currentPage: 1,
        filter: { name: "", bestTimes: "", suitablePeople: "" },
        pageSize: 6,
      },
      scenList: [],
      scneActice: "1",
    };
  },
  methods: {
    getpageList() {
      GetScenerySpotPage(this.params).then((res) => {
        if (res.data?.success == true) {
          const data = res.data.data.list;

         var pics = data[0].photoUrls.split(",");
           this.carouselData = pics;
          data.forEach((element) => {
            element.photoUrls = element.photoUrls.split(",")[0];
          });
          this.pageData = data[0];
          this.scenList = data;
          this.actcal = pics[0];
          this.currentphotoUrls = pics[0];
          this.currentintroduction = this.pageData.introduction
          this.currentid = data[0].id;
        }
      });
    },
    setcurrentPage(id, introduction, photoUrls) {
      this.currentphotoUrls = photoUrls;
      this.currentid = id;
      this.currentintroduction = introduction;
    },
    calChange(a) {
      this.actcal = this.carouselData[a];
    },
  },
  mounted() {
    this.getpageList();
    
  },
  watch: {
    scneActice(val) {
     this.pageData= this.scenList.filter(v => v.id===val)[0]
    }
  }
};
</script>
<style scoped>
.videotag{
  object-fit: cover;
}
.rright{
  height: 520px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.scen-item:hover a {
  background: #3370ff;
  color: #fff;
}
.scen-item.isactive a {
  background: #3370ff;
  color: #fff;
}
.scen-item a {
  display: block;
  width: 100%;
  background: #f1f5f9;
  line-height: 42px;
  font-size: 18px;
  color: #3c414d;
  border-radius: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.scen-item {
  position: relative;
  width: 48%;
  height: 50px;
  /* line-height: 50px; */
  text-align: center;
}
.scenlist {
  padding-top: 16px;
}
.scenlist>ul{
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.info-text p {
  }
.info-text {
  line-height: 30px;
  text-indent: 28px;
  /* width: calc(100% - 260px); */
  height: 125px;
  padding: 4px 12px;
  box-sizing: border-box;
  font-family: "微软雅黑";
  font-size: 16px;
  color: #666;
  /* height: 250px; */
  /* text-overflow: -o-elllipsis-lastline; */
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  background: #caf4ff;
  /* overflow-y: auto; */
}
.info-box {
  /* padding: 10px; */
  /* border-bottom: 1px solid #666; */
}
.info_pic {
  width: 100%;
  height: 190px;
}
.more {
  font-size: 18px;
  cursor: pointer;
}
.banner2-title-text {
  font-size: 24px;
  color: #367bff;
  font-weight: bold;
}
.banner2-title {
  /* height: 40px; */
  padding: 0 5px;
  /* line-height: 40px; */
  /* border-bottom: 1px solid #ccc; */
}
.col-box {
  /* width: 85%;
    margin: auto; */
  margin-top: 10px;
}
.s-cal-b {
  width: 100%;
  height: 160px;
  position: absolute;
  bottom: 0;
  left: 0;
  /* background: rgba(0, 0, 0, .4) */
}
.s-cal {
  width: 100%;
  height: 560px;
  overflow: hidden;
  position: relative;
}
</style>
