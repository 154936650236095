<template>
    <div class="hotel">
        <div class="classify">
            <ul class="tour-list">
                <li class="tour-item" :class="{act1:params.filter.suitablePeople == ''}" @click="suitablePeople('')">
                    <div class="c-list">全部</div> 
                </li>
                <li class="tour-item" :class="{act1:params.filter.suitablePeople == '家庭游'}" @click="suitablePeople('家庭游')">
                    <div class="c-list">家庭游</div> 
                </li>
                <li class="tour-item" :class="{act1:params.filter.suitablePeople == '亲子游'}" @click="suitablePeople('亲子游')">
                    <div class="c-list">亲子游</div>
                </li>
                <li class="tour-item" :class="{act1:params.filter.suitablePeople == '情侣游'}" @click="suitablePeople('情侣游')">
                    <div class="c-list">情侣游</div>
                </li>
                <li class="tour-item" :class="{act1:params.filter.suitablePeople == '团体游'}" @click="suitablePeople('团体游')">
                    <div class="c-list">团体游</div>
                </li>
            </ul>
        </div>
        <div class="cardlist" v-loading="loading">
            <div class="cardItem" v-for="item in pageData" :key="item.id">
                <div class="imgbox">
                    <el-image fit="cover" style="width:100%;height:100%"  :src="item.photoUrls.split(',')[0]"></el-image>
                </div>
                <div class="infos">
                    <div class="name ellipsis">{{item.name}}</div>
                    <div class="info">{{item.introduction}}</div>
                    
                </div>
                <div class="tag">
                    <div class="" v-for="v in item.playItems.split(',').slice(0,4)" :key="v+'it'">{{v}}</div>
                    <div v-if="item.playItems.split(',').length>4">···</div>
                </div>
                <div class="more" @click="goDetail(item.id)">
                    <span>更多</span>
                    <i></i>
                </div>
            </div>
        </div>
        <!-- <el-row :gutter="32">
            <el-col :span="8">
                <div class="tour-menu">
                    <div class="tour-title"><span class="iconfont icon-fuzhushuxian"></span><span>项目</span></div>
                    <ul class="tour-menu-list">
                        <li class="tour-menu-item" v-for="item in tourMenu" :key="item+'tour'">
                            <a href="javascript:;">
                                <div :class="{act2:params.filter.playItems == item}" @click="playItems(item)">{{item}}</div>
                            </a>
                        </li>
                    </ul>
                </div>
            </el-col>
            <el-col :span="16">
                <el-row :gutter="20" class="hotel-box" v-if="pageData.length>0">
                    <el-col :span="8" v-for="item in pageData" :key="item.id">
                        <router-link :to="`/sharehousedetail?id=${item.id}`">
                            <div class="hotel-item ellipsis" :style="{'background-image':`url(${item.photoUrls.split(',')[0]})`}">{{item.name}}</div>
                        </router-link>
                    </el-col>
                </el-row>
                <div class="hotel-box" v-else>
                    <el-empty description="暂无数据"></el-empty>
                </div>
            </el-col>
        </el-row> -->
    </div>
</template>
<script>
import {GetSharedFarmPage,GetYWXM} from '@/api/home.js'
export default {
    data(){
        return{
            tourMenu:['农家饭','烧烤','采摘','溜冰','垂钓','漂流','温泉','赏花','喝茶','棋牌','扩展','划船','会议','挖野菜','骑马','健身','禅修','篝火','真人CS','游泳','攀岩','卡拉ok','滑雪','其他'],
            hotel:[
                {title:'民俗客栈',img:require('../../../public/img/hotel1.png')},
                {title:'鸽子沟避暑山庄',img:require('../../../public/img/hotel2.png')},
                {title:'天境宗姆山庄',img:require('../../../public/img/hotel3.png')},
                {title:'乳源桂缘农庄',img:require('../../../public/img/hotel4.png')},
                {title:'佛宝古镇农庄',img:require('../../../public/img/hotel5.png')},
                {title:'永盛农家院',img:require('../../../public/img/hotel6.png')},
            ],
            act1Value:'',
            isact2value:'',
            params:{
                currentPage: 1,
                filter:{businessType:'精品民宿'},
                // dynamicFilter:{field:"suitablePeople",operator:'Contains',value:"情侣游"},
                pageSize: 4,
                // dynamicFilter:dynamicFilter,
                orderName:'Sort',
                orderDesc: false,
            },
            pageData:[],
            loading:false,
        }
    },
    methods:{
        goDetail(id){
            this.$router.push(`/sharehousedetail?id=${id}`)
        },
        getPageData(){
            this.loading = true
            GetSharedFarmPage(this.params).then(res=>{
                this.loading = false
                this.pageData = res.data.data.list;
            }).catch(()=>{this.loading = false})
        },
        suitablePeople(suitablePeople){
            if(this.params.filter.suitablePeople == suitablePeople){
                this.params.filter.suitablePeople = '';
            }else{
                this.params.filter.suitablePeople = suitablePeople;
            }
            this.getPageData();
        },
        playItems(playItems){
            this.params.filter.playItems = playItems;
            this.getPageData();
        }
    },
    mounted(){
        this.getPageData();
    }
}
</script>
<style scoped>
.cardlist{
    height: 448px;
    display: flex;
    justify-content: space-between;
    transition: all 0.5s;
}
.cardItem{
    width: 24%;
    box-shadow: 0 3px 8px #bbb;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: all 0.8s;
}
.cardItem:hover .tag>div{
    background: #367bff;
    color: #fff;
    border-color: #fff;
}
.cardItem:hover .more{
    height: 40px;
}
.more{
    background: #367bff;
    text-align: center;
    line-height: 40px;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    height: 0;
    overflow: hidden;
    transition: all 0.2s;
}
.more>i{
    display: inline-block;
    width: 50px;
    height: 16px;
    background: url(../../assets/img/hot-icon/more2.png) no-repeat center center;
    vertical-align: middle;
}
.infos{
    padding: 6px 12px;
}
.infos .name{
    font-size: 16px;
    font-weight: bold;
}
.tag{
    display: flex;
    margin-top: 20px;
    padding: 0 10px;
    margin-bottom: 16px;
}
.tag>div{
    padding: 6px 10px;
    border: 1px solid #bbb;
    margin-right: 10px;

}
.infos .info{
    font-size: 14px;
    line-height: 24px;
    text-indent: 24px;
    color: #666;
    display:-webkit-box;
    overflow:hidden;
    text-overflow:ellipsis;
    -webkit-line-clamp:3;
    -webkit-box-orient:vertical;
    margin-top: 10px;

}
.imgbox{
    height: 225px;
}
.hotel-box{
    padding-top: 40px;
    height: 540px;
}
.hotel-item{
    height: 230px;
    line-height: 230px;
    font-size: 20px;
    color:#fff;
    margin-bottom: 20px;
    text-align: center;
    background-size: 100% 100%;
}
.tour-menu-item div:hover,.tour-menu-item div.act2{
    background: rgb(216, 214, 214);
}
.tour-menu-item div{
    width: 80px;
    height: 40px;
    background: #fff;
    line-height: 40px;
    text-align: center;
    font-family: '黑体';
    font-size: 16px;
    margin:0 auto;
}
.tour-menu-item{
    width: 25%;
    float: left;
    margin-top:20px;
}
.tour-item{
    width: 124px;
    height: 42px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 16px;
}
.tour-item>div{
    width: 124px;
    /* height: 70px; */
    /* background: #0069c9; */
    text-align: center;; 
    box-sizing: border-box;
    position: relative;
    transition: all 0.4s;
}
.tour-item>div:not(.tour-item:last-child>div){
    border-right: 1px solid #ccc;
}
.tour-item.act1,.tour-item:hover{
    background: #0069c9;
    cursor: pointer;
    color: #fff;
}
.tour-item.act1>div.c-list,.tour-item:hover .c-list{
    border-color: #0069c9 !important;
    /* border-right: 1px solid transparent; */
}
.tour-list{
    display: flex;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
}
.tour-title>span:first-child{
    font-size: 22px;
}
.tour-title{
    color: #0069c9;
    font-size: 20px;
}
.hotel{
    padding: 0 5px;
}
</style>