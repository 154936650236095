<template>
    <div class="news">
        <div class="left">
            <div class="l-video">
                <video class="videotag" src="../../../public/video/video4.mp4" poster="../../assets/img/hot-icon/huahai.jpg" controls width="100%" height="346px"></video>
            </div>
            <div class="l-info info-box">
                <div class="info-title">青海省西宁市湟中区土门关乡上山庄村</div>
                <div class="infos">
                    上山庄花海是西宁周边的一处观赏花海胜地。这里空气清新、景色宜人。山坡上种满了薰衣草、海棠、矢车菊、榆叶梅、红刺玫瑰等20余种的观赏性植物花卉，花期从七月开始一直持续到国庆节，鲜花满山遍野绽放。纵赏千亩花海，畅享田园风光，还有农家乐、果蔬采摘、乡村风情体验等，是田园乡村游的不错选择
                </div>
            </div>
        </div>
        <div class="center" @click="goDetail(list1.id)">
            <div class="l-img">
                <!-- <img :src="list1.photoUrls[0]" alt="" height="100%"> -->
                <el-image fit="cover" style="width:100%;height:100%"  :src="list1.photoUrls[0]"></el-image>
            </div>
            <div class="l-info info-box">
                <div class="info-title">{{list1.name}}</div>
                <div v-html="list1.introduction" class="infos">
                    
                </div>
            </div>
        </div>
        <div class="right" @click="goDetail(list2.id)">
            <div class="l-img">
                <!-- <img :src="list2.photoUrls[0]" alt="" height="100%"> -->
                <el-image fit="cover" style="width:100%;height:100%"  :src="list2.photoUrls[0]"></el-image>
                
            </div>
            <div class="l-info info-box">
                <div class="info-title">{{list2.name}}</div>
                <div v-html="list2.introduction" class="infos">
                    
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import { GetInformationPage } from '@/api/home'
import {  GetVillagePage} from "@/api/home";

export default {
    props:{video3:String},
    data(){
        return{
            newsBanner: [],
            subBanner: [],
            newsRact: '1',
            newsRData: [],
            params:{  currentPage:1, pageSize:6 ,filter:{infoType:257287600083013, recommendTypes:'首页'},},
            params2:{ filter:{infoType:257287600083013, recommendTypes:'热门'}, currentPage:1, pageSize:6} ,
            sanxiangInfoparams: {
                currentPage: 1,
                filter: {
                    infoType: "249253465337925",
                    recommendTypes:'首页'
                },
                pageSize: 999,
            },
            list1:{photoUrls:[]},
            list2:{photoUrls:[]},
        }
    },
    created(){
    },
    mounted(){
        this.getBannerData();
        // this.onNewsRChange('1', 257287600083013);
    },
    methods:{
        goDetail(id){
            this.$router.push(`/villiagedetail?id=${id}`)
        },
        //获取newsBanner、subBanner（资讯/美丽乡村）
        getBannerData(){
            //recommendTypes:'???'
            const data = { currentPage:1, pageSize:2 };
            GetVillagePage(data).then(res => {
                if (res.data?.success == true) {
                    const data = res.data.data.list;
                    data.forEach(item=>{
                        item.photoUrls = item.photoUrls.split(',')
                    });
                    this.list1 = data[0];
                    this.list2 = data[1];
                }
            })
        },
        onNewsRChange(index, infoType) {
            this.newsRact = index;
            this.params2.filter.infoType = infoType;
            this.getnewsRData(infoType);
        },
        getnewsRData(infoType) {
            //recommendTypes:'???'
            const data = { filter:{infoType:infoType, recommendTypes:'首页'}, currentPage:1, pageSize:6 };
            GetInformationPage(data).then(res => {
                if (res.data?.success == true) {
                    const data = res.data.data.list;
                    data.forEach(d => {
                        d.releaseDate = d.releaseDate.substr(0, 10);
                    })
                    this.newsRData = data;
                }
            })
        },
    },
}
</script>
<style scoped>
.news{
    display: flex;
    justify-content: space-between;
    height: 460px;
}
.news>div{
    box-shadow: 0 5px 10px #ccc;
    overflow: hidden;
}
.left{
    width: 48%;
    height: 100%;
}
.left .infos{
    line-height: 24px;
    display:-webkit-box;
    overflow:hidden;
    text-overflow:ellipsis;
    -webkit-line-clamp:2;
    -webkit-box-orient:vertical;
}
.right .infos,.center .infos{
    padding-top: 10px;
    display:-webkit-box;
    overflow:hidden;
    text-overflow:ellipsis;
    -webkit-line-clamp:4;
    -webkit-box-orient:vertical;
}
.l-video{
    height: 354px;
}
.info-box{
    padding:16px;
    font-size: 14px;
    line-height: 30px;
}
.info-title{
    color: #367bff;
    font-size: 20px;
}
.center{
    cursor: pointer;
    width: 25%;
}
.l-img{
    height: 230px;
}
.right{
    width: 25%;
    cursor: pointer;
}
</style>